import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route , Navigate} from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import Gallery from './Gallery.jsx';
import Project from './Project';
import { UserContext } from './UserContext';
import { Box, Snackbar, SnackbarContent } from '@mui/material';

import MyAppBar from './MyAppBar';
import NewProject from './NewProject';
import Checkout from './Checkout';

import serverurl from './Globals';
import FirebaseUI from './FirebaseUI';
import { getUser, getProject } from './Apis';
import Tasks from './Tasks';
import MyRenders from './MyRenders';
import EditorialGallery from './EditorialGallery';
import PromptCreate from './PromptCreate';
import Footer from './Footer';
import TOS from './TOS'
import Privacy from './PrivacyPolicy';
import AllProjects from './AllProjects';
import HowTo from './HowTo';


function Main() {
  const { user } = useContext(UserContext);
  
  const [galleryData, setGalleryData] = useState([]);
  const [enrichedGalleryData, setEnrichedGalleryData] = useState(galleryData);
  const [isLoadingGallery, setIsLoadingGallery] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [gallerySize, setGallerySize] = useState(0);
  


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentSuccess = urlParams.has('payment_success');

    if (paymentSuccess) {
      // show snackbar here
      console.log("successful payment");
      setSnackbarOpen(true)
      window.history.replaceState(null, '', window.location.origin);
    }
  }, []);


 /// This part is relevant only for Admin access
const loadGallery = (fromIndex) => {
    fetch(`${serverurl}/api/gallery?start=${fromIndex}`)
    .then((response) => response.json())
    .then((data) => {
      setGalleryData([...galleryData, ...data]);
      setIsLoadingGallery(false);
      
    })
    .catch((error) => {
        console.error('Error fetching gallery:', error);
    });
};


const handleLoadMore = () => {
  loadGallery(galleryData[galleryData.length-1].id);
};



  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Router>
        <div className="App">
        
          <Box sx={{ flexGrow: 1 }}>
              <MyAppBar user={user} />
              {/* <Box component="main" sx={{ p: 5 , pt:10}}> */}
              <Box component="main" id="main-container" >
                  {/* <Toolbar /> */}
                  
                  <Routes>
                  <Route exact path="/" element={<EditorialGallery galleryData={galleryData} handleLoadMore={handleLoadMore} />}></Route>
                    <Route path="/all" element={<AllProjects galleryData={galleryData} handleLoadMore={handleLoadMore}/>} />   
                    <Route exact path="/gallery/:id" element={<Project galleryData={galleryData} />}></Route>
                    <Route exact path="/new" element={<NewProject />}></Route>
                    <Route exact path="/renders" element={<MyRenders shouldShowButton={true} />}></Route>
                    <Route exact path="/home" element={<Homepage />}></Route>
                    <Route exact path="/checkout" element={<Checkout />}></Route>
                    <Route exact path="/terms-of-service" element={<TOS />}></Route>
                    <Route exact path="/privacy-policy" element={<Privacy />}></Route>
                    <Route exact path="/login" element={<FirebaseUI  title='Sign in to TypeX'/>}></Route>
                    <Route exact path="/how-to" element={<HowTo/>}></Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                  <Footer/>
              </Box>
          </Box>
          <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <SnackbarContent
                    message="Thank you for your payment!"
                    style={{ backgroundColor: 'green' }}
                />
            </Snackbar>
        </div>
    </Router>
  );
}

export default Main;

function Homepage() {

  const [homepageHtml, setHomepageHtml] = useState();

  const getHomepage = () =>{
    fetch(`${serverurl}/api/home`)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      
    })
    .catch((error) => {
        console.error('Error fetching gallery:', error);
    });
  }
  return (
    <div>
    <div onClick={getHomepage}>Hello home</div>
   {/* <div dangerouslySetInnerHTML={{ __html: homepageHtml }} />; */}
   </div>
  )
}
