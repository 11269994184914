import React, { useEffect } from 'react';
import firebase from './firebase.js';
import * as firebaseui from 'firebaseui';
import { Box, Typography } from '@mui/material';

const prevUrl = localStorage.getItem('prevUrl');
let successUrl;
if(prevUrl){
    successUrl = prevUrl;
    localStorage.removeItem('prevUrl');
}
else successUrl = '/';

const uiConfig = {
  signInSuccessUrl: successUrl,  
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '/terms-of-service',
  privacyPolicyUrl: '/privacy-policy',
  callbacks: {
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('loader').style.display = 'none';
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
//   signInFlow: 'popup',
};

const ui = new firebaseui.auth.AuthUI(firebase.auth());


const FirebaseUI = ({title}) => {
  useEffect(() => {
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);


  return ( 
    <Box id="login-container" sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100vh'}}> 
        {/* <Box sx={{width:'50%'}}> */}
        
        <img src='./typex.png' alt="typex logo" width='80px'/>
        <Typography variant='h6' style={{color:'#666'}}>{title}</Typography>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
        {/* </Box> */}
    </Box>
    );
};

export default FirebaseUI;