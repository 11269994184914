import React, { useRef, useState, useEffect, Suspense } from 'react';
import { Box, Typography} from '@mui/material';
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { Environment,Plane ,Background, OrbitControls, Html, useProgress, ContactShadows} from "@react-three/drei";
import useAspectRatio from './useAspectRatio'; 
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import { StraightenModel, Model } from './threedeeutils';
import { Mesh, PlaneBufferGeometry, ShadowMaterial } from 'three';
import { suspend } from 'suspend-react';
const park2Url = 'https://firebasestorage.googleapis.com/v0/b/typexcad-cc750.appspot.com/o/assets%2Frooitou_park_1k.pic?alt=media&token=17215672-e34d-4449-9c03-b34c891cb077&_gl=1*jjujho*_ga*MjEyMjQxNTcxMC4xNjgzMDI0MDc1*_ga_CW55HF8NVT*MTY4NjcyMTY4MC44OC4xLjE2ODY3MjM4MTUuMC4wLjA.';
const parkUrl = 'https://firebasestorage.googleapis.com/v0/b/typexcad-cc750.appspot.com/o/assets%2FHDR_111_Parking_Lot_2_Env.hdr?alt=media&token=201bf5fc-6b85-4644-b703-b42d279382e5&_gl=1*q3cdqp*_ga*MjEyMjQxNTcxMC4xNjgzMDI0MDc1*_ga_CW55HF8NVT*MTY4NjcyMTY4MC44OC4xLjE2ODY3MjM0NjMuMC4wLjA.';
function ModelLoader() {
    const { progress } = useProgress();
    return <Html center>{progress} % loaded</Html>
  }
function SetCameraPosition() {
    const { camera } = useThree();

    useEffect(() => {
        camera.position.x = -3;
        camera.position.y = 1.5;
        camera.position.z = 4;
        
        camera.updateProjectionMatrix();
    }, [camera]);

    return null;
}
// function CameraAnimation2() {
    
//     const { camera } = useThree();
//     const start = new THREE.Vector3(3, 1.5, -4);
//     const end = new THREE.Vector3(-3, 1.5, 4);
//     const temp = new THREE.Vector3();
//     let time = 0;
//     const duration = 1000;
//     const [animationId, setAnimationId] = useState(null);
  
//     useFrame(({ clock }) => {
//         time += clock.getElapsedTime();
//         const progress = Math.min(1, time / duration);
       
//         temp.lerpVectors(start, end, progress);
//         camera.position.copy(temp);
//         camera.lookAt(0, 0, 0);
//         if (progress >= 1) {
//             //onComplete();
//             stopAnimation();
//         }  
//     });
//     const stopAnimation = () => {
//         if (animationId !== null) {
//           cancelAnimationFrame(animationId);
//           setAnimationId(null);
//         }
//       };
    
//       useEffect(() => {
//         return () => stopAnimation();
//       }, []);
//     return null;
//   }

  

function FbxPlayer({ onUploadPerspective, triggerUseView, onCompleteUseView , objUrl, type}) {

    
    const [objectUrl, setObjectUrl] = useState(null);
    const { width, height } = useAspectRatio(4, 3);
    const canvasRef = useRef(null);
    

    /// POP OVER
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    /// END OF POP OVER
    useEffect(() => {
        if (triggerUseView) {
            handleUseView();
            onCompleteUseView();
        }
      }, [triggerUseView, onCompleteUseView]);

    useEffect(() => {
    if (objUrl) {
        fetch(objUrl)
            .then((response) => response.blob())
            .then((blob) => {
            const url = URL.createObjectURL(blob);
            setObjectUrl(url);
            // URL.revokeObjectURL(objectUrl);
            })
            .catch((error) => console.error("Error fetching OBJ file:", error));
        }
    return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
        }
      };
    }, [objUrl]);

    

    function ShadowFloor({ position = [0, 0, 0], rotation = [-Math.PI / 2, 0, 0], scale = [100, 100, 1] }) {
        const shadowMaterial = new ShadowMaterial();
        shadowMaterial.opacity = 0.5;
      
        return (
          <mesh
            position={position}
            rotation={rotation}
            scale={scale}
            receiveShadow
            material={shadowMaterial}
            geometry={new PlaneBufferGeometry()}
          />
        );
      }
    
      
    const handleUseView = () => {
        const canvas = canvasRef.current;
        const dataUrl = canvas.toDataURL("image/png");
        const blob = dataURLToBlob(dataUrl);
        const formData = new FormData();
        formData.append("file", blob, "perspective"+Math.floor(100000 + Math.random() * 900000)+".png");
      
        onUploadPerspective(formData);

      }

      function dataURLToBlob(dataURL) {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }

      
    return (
        <Box className="fbxPlayer" width='100%' id="fbx-player-wrppaer">
           
            <Box id="canvas-wrapper" style={{ width:'100%', border:'1px solid #d5d5d5', position:'relative'}} onKeyDown={(event) => event.preventDefault()}>
                <Canvas shadows style={{ width: '100%', height, minHeight:'300px'}} onCreated={({ gl }) => {gl.setClearColor("#e0e0e0");}} ref={canvasRef} gl={{ preserveDrawingBuffer: true }} >
                <SetCameraPosition />
                
                    <OrbitControls enableRotate target={[0, 1.5, 0]}/> 
                    
                    <directionalLight
                        intensity={1}
                        position={[-5,10,-5]}
                        castShadow
                        shadow-mapSize-height={512}
                        shadow-mapSize-width={512}
                    />
                    <ambientLight color={'#dce7f5'} intensity={.2}/>
                    <Suspense fallback={<ModelLoader />}>
                    <Model castShadow objUrl={objectUrl} type={type}/>
                    {/* <ContactShadows rotation-x={Math.PI / 2} position={[0, 0, 0]} opacity={1} width={5} height={5} blur={1} far={1} /> */}
                    {/* <ShadowFloor position={[0, 0, 0]} /> */}
                    </Suspense>
                    {/* <Environment files={parkUrl} blur={0.5} /> */}
                    <Environment files={park2Url} blur={0.5} />
                    {/* <Environment preset="park" blur={0.5} /> */}
                    <EffectComposer>
                        {/* <SSAO
                            blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
                            samples={10}
                            radius={10}
                            intensity={50}
                        /> */}
                    </EffectComposer>
                </Canvas>
            </Box>
            <Box sx={{position:'absolute', top:'0', right:'0', padding:'8px'}}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose} >
               <InfoOutlinedIcon sx={{color:'#666'}} />
            </Box>
            {/* <Box>
                <StraightenModel />
            </Box> */}
            <Popover
                id="controls-info"
                sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                    <Box sx={{color:'#666', p:2}} >
                        <Typography>
                            Use <img src="/mouse_left.png" alt="mouse left" style={{position: 'relative', top: '7px', width:'24px'}} /> and drag to rotate
                        </Typography>
                        <Typography sx={{mt:-1}}>
                            Use <img src="/pinch.png" alt="pinch" style={{position: 'relative', top: '10px', width:'32px'}} /> or <img src="/mouse_scroll.png" alt="mouse scroll" style={{position: 'relative', top: '7px', width:'24px'}} /> to zoom in and out
                        </Typography>
                        <Typography>
                            Use <img src="/mouse_left.png" alt="mouse left" style={{position: 'relative', top: '7px', width:'24px'}} /> + <b>cmd/ctrl</b> and drag to move
                        </Typography>
                    </Box>
            </Popover>
        </Box>
        )
        
    
};

export default FbxPlayer;

