import React from "react";
import {  Typography, Box, Link , Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function EmptyGallery(){

    const navigate = useNavigate();
    const handleClickUpload = () => {
        navigate("/new");
      };

    return(
        <Box sx={{ p: 5 , pt:10, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
             <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                 <Button variant="contained" sx={{ mb: 1 }} onClick={handleClickUpload}>Create New Render</Button>
             </Box>
            <Typography variant="heading4">Nothing to show yet. Click here to <Link href='/new' underline='none'>start creating</Link> </Typography>
        </Box>
    );
};
export default EmptyGallery;