import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {  Grid , Modal, Box, Button, Skeleton} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Pagination from '@mui/material/Pagination';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


import mixpanel from './Mixpanel';

import AdminTools from './AdminTools';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 0,
  };

const cardStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '60px',
    width: '100%'
}

function Gallery({ galleryData, isLoadingGallery, handleLoadMore, shouldShowButton, isAdmin}) {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    
    const handleClickUpload = () => {
        mixpanel.track('Clicked Create New', {'source':'gallery'});
        navigate("/new");
      };

    const handleProjectClick = (projectId)=>{
        storeCurrentRoute();
        navigate(`/gallery/${projectId}`);
    };
    const storeCurrentRoute = () => {
        const currentRoute = location.pathname; // Get the current route
        localStorage.setItem('lastPreviousRoute', currentRoute); // Store it as the last previous route
    };

    
    const handlePaginationChange = (event, value) => {
        
    };

    const handleFavoriteClick = () => {

    };
    return (
        <>
          {shouldShowButton && <Box sx={{ height:'100px', width:'100%', position:'fixed', bottom:0, zIndex:'100' ,display: {sm:'none', xs:'flex'}, flexDirection: 'column', alignItems: 'center' }}>
          <Button sx={{ height: '56px', borderRadius:'28px',
                        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
                        '&.Mui-disabled': {
                        color: '#999', // Specify the text color for disabled state
                        backgroundColor: '#ccc', // Specify the background color for disabled state
                        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)'
                        }
                        }} disabled={isLoadingGallery? true:false} variant="contained" onClick={handleClickUpload}><AutoAwesomeIcon />&nbsp;&nbsp;Create new</Button>
          {/* <SpeedDial onClick={handleClickUpload} ariaLabel="SpeedDial create new" sx={{ position: 'absolute', bottom: 16, right: 16 }} icon={<AutoAwesomeIcon />}></SpeedDial> */}
        </Box>}
        <Box sx={{ p: 5 , pt:10}}>
        {shouldShowButton && <Box mb={2} sx={{ display: {sm:'flex', xs:'none'}, flexDirection: 'column', alignItems: 'center' }}>
          <Button disabled={isLoadingGallery? true:false} variant="contained" sx={{ mb: 1 }} onClick={handleClickUpload}><AutoAwesomeIcon/>&nbsp;&nbsp;Create New Render</Button>
        </Box>}
      
        {isLoadingGallery && <Grid container spacing={2}>
        {[...Array(24)].map((_, index) => 
             <Grid item key={`skeleton-${index}`} xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <Skeleton variant="rectangular"  height={220} animation="wave" />
                    <Box sx={{p:2}} >
                        <Skeleton variant="text" sx={{ fontSize: '1rem' , mr:4}} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' , mr:1}} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' , mr:3}} />
                    </Box>
                </Card>
            </Grid>)}
        </Grid>}
        <Grid container spacing={2}>
          {galleryData?.map((project, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ maxWidth: 345 }} onClick={()=>{handleProjectClick(project.id)}}  >
                        <CardActionArea sx={{position:'relative'}} 
                        onMouseEnter={(e) => {
                            e.currentTarget.querySelector('.overlay').classList.add('show');
                            e.currentTarget.querySelector('.overlay-label').classList.add('show');
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.querySelector('.overlay').classList.remove('show');
                            e.currentTarget.querySelector('.overlay-label').classList.remove('show');
                          }}
                        >
                           <CardMedia
                            component="img"
                            height="220"
                            image={project.images ? project.images[Math.floor(Math.random()*project.images.length)] : './typex_cover.png'}
                            alt="first image"
                            />
                            {project.images && <Typography sx={{position:'absolute',
                             top:190,
                             left:20, 
                             textTransform:'uppercase', 
                             color:'white',
                             FontWeight:'bold',
                             textShadow:'0px 0px 4px black'
                            }}   
                             variant='caption'
                             >
                                {project.images.length} Renders
                            </Typography>}
                            <CardMedia
                            className="overlay"
                            component="img"
                            height="220"
                            image={project.images ? (project.originalthumburl || project.originalimageurl) : './typex_cover.png'}
                            alt="first image"
                            sx={{position:'absolute', top:0
                                }}
                            />
                            <Typography className="overlay-label" sx={{position:'absolute',
                             top:10,
                             left:10, 
                             backgroundColor:'#6434E4', 
                             px:1.5,
                             py:.5, 
                             textTransform:'uppercase', 
                             color:'white',
                             borderRadius:'16px',
                             FontWeight:'bold'
                            }}   
                             variant='caption'
                             >
                                before
                            </Typography>
                            <CardContent>
                                {isAdmin && galleryData[index].creator && 
                                    <Typography gutterBottom variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Made by&nbsp;</span> 
                                        <span><b>{project.creator.displayName}</b></span>  
                                    </Typography>}
                                <Typography variant="body2" color="text.secondary" sx={cardStyle}>
                                <b>Prompt:</b>&nbsp;{project.prompttext}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    {isAdmin && <>
                    <AdminTools project={project}/>
                   
                    </>}
                </Grid>
                
            ))}
         
        </Grid>
        {galleryData.length%48 === 0 && <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt:4}}>
          <LoadingButton loadingPosition="end"
                                endIcon={<NavigateNextIcon />} 
                                loading={isLoadingGallery}
                                variant="contained" 
                                onClick={handleLoadMore}
                                disabled={isLoadingGallery}
                                sx={{mt:4, boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.5)',}}>
                            <span>Load More</span>
            </LoadingButton>
          {/* <Pagination count={Math.floor(gallerySize/20)+1} color="primary" onChange={handlePaginationChange}/> */}
        </Box>}
        </Box>
        </>
    )
}

export default Gallery;