import React from "react";
import { Box } from "@mui/material";

function Privacy (){
    return(
        <Box sx={{p:5, pt:10,pb:10, height:'100%',}}>
            <h3>Privacy Policy</h3>
            <p>This Privacy Policy governs the manner in which TypeX collects, uses, maintains, and discloses information collected from users (each, a "User") of the TypeX website and services (the "Service"). This privacy policy applies to the Service and all products and services offered by TypeX.</p>
            <h4>1. Personal Identification Information</h4>
            <p>We may collect personal identification information from Users in various ways, including but not limited to when Users visit our website, register on the website, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Service. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and credit card information. Users may, however, visit our website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Service-related activities.</p>
            <h4>2. Non-personal Identification Information</h4>
            <p>We may collect non-personal identification information about Users whenever they interact with our Service. Non-personal identification information may include the browser name, the type of computer, and technical information about Users' means of connection to our Service, such as the operating system and the Internet service providers utilized and other similar information.</p>
            <h4>3. Web Browser Cookies</h4>
            <p>Our Service may use "cookies" to enhance the User experience. Users' web browsers place cookies on their hard drives for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent. If they do so, note that some parts of the Service may not function properly.</p>
            <h4>4. How We Use Collected Information</h4>
            <p>TypeX may collect and use Users' personal information for the following purposes:</p>
            <ul>
            <li>To improve customer service: Information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
            <li>To personalize the user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Service.</li>
            <li>To process payments: We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
            <li>To send periodic emails: We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests.</li>
            </ul>
            <h4>5. How We Protect Your Information</h4>
            <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Service.</p>
            <h4>6. Sharing Your Personal Information</h4>
            <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
            <h4>7. Compliance with Children's Online Privacy Protection Act</h4>
            <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Service from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>

            <h4>8. Changes to This Privacy Policy</h4>
            <p>TypeX has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
            <p>Please note that this is a general outline of a privacy policy, and you should consult with a legal professional to create a comprehensive and legally binding document tailored to your specific needs and jurisdiction.</p>
            <p>Last updated: May 10th 2023</p>
    </Box>
    )
}

export default Privacy;