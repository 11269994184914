import React, { useEffect, useState } from "react";
import { Box , Skeleton, Stack} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import './App.css'; // or the correct path to your App.css file



function CaseStudies ({gallery}) {
    const [randomProjects, setRandomProjects] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [wordIndex, setWordIndex] = useState(0);
    const words = ['a sketch', 'a 3D model', 'a picture'];
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));    
    const [isReady, setIsReady] = useState(false);
    const containerHeight = matches ? '400px' : '600px';
    
    useEffect(() => {
        raffleProject();
    }, [gallery]);
 
 
    useEffect(() => {
        setTimeout(() => {
            const firstImageElement = document.getElementById('source-wrapper-9');
            firstImageElement && firstImageElement.classList.add('show');
        }, 500); // delay execution to ensure Carousel has finished initializing
    }, [randomProjects]);

    const raffleProject = () => {
        if (gallery && gallery.length) {
            const result = [];
            while (result.length < 10) {
                const randomIndex = Math.floor(Math.random() * gallery.length);
                if (result.indexOf(gallery[randomIndex]) === -1) result.push(gallery[randomIndex]);
            }
            setRandomProjects(result);
        }
    };

    useEffect(()=>{
        if(randomProjects.length>0)
            setIsReady(true);
    },[randomProjects]);
    return(
        <Box id="case-studies-container" sx={{ width:'100vw'}}>
            <Box id='case-studies-wrapper' sx={{ alignItems:'center', display:'flex', justifyContent:'center', flexDirection:'column', py:4, px:{sm:20, xs:4}}}>
            <Box sx={{width:'100%' , display: 'flex', justifyContent: 'center', alignItems: 'baseline', mb: 4 , flexDirection:'row'}}>
               
                <Typography variant='h5' id='case-studies-title' sx={{ display: {sm:'flex', xs:'none'}}}>
                    See what you can achieve in less then 2 mintues with&nbsp;
                </Typography>
                <Typography variant='h5' sx={{width:{sm:'120px', xs:'80px'},position:'relative', display:{xs:'none', sm:'flex'}, whiteSpace: 'nowrap'}}>
                    {words.map((word, index) => (
                        <span key={index} className={`changing-word ${wordIndex === index ? 'visible' : ''}`}>{word}</span>
                    ))}
                </Typography>
                {/* For Mobile */}
                <Typography variant='body1' id='case-studies-title' sx={{ display: {sm:'none', xs:'flex'}}}>
                    Created in 2 mintues from &nbsp;
                </Typography>
                <Typography variant='body1' sx={{width:'80px',position:'relative', display: {xs:'flex', sm:'none'}, whiteSpace: 'nowrap'}}>
                    {words.map((word, index) => (
                        <span key={index} className={`changing-word ${wordIndex === index ? 'visible' : ''}`}>{word}</span>
                    ))}
                </Typography>
                
              
            </Box>
            {isReady && <>
            {randomProjects.length > 0 && <Carousel
            showThumbs={false}
            showStatus={false}
            emulateTouch
            useKeyboardArrows
            infiniteLoop={true}
            autoPlay={true}
            transitionTime={600}
            interval={4000}
            showArrows={true}
            onChange={(currentIndex) => {
                setCurrentSlide(currentIndex);
                setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                }}
            >  
            {randomProjects?.map((project, index) => (
                <div key={index} style={{position:'relative'}}>
                    <img id={`result-${index}`} src={project.images[0]} style={{height:containerHeight ,objectFit:'cover'}}/>
                    <Typography sx={{position:'absolute',
                             top:20,
                             left:20, 
                             backgroundColor:'#66DD9E', 
                             px:2,
                             py:.5, 
                             textTransform:'uppercase', 
                             color:'white',
                             borderRadius:'24px',
                             lineHeight:2
                            }}   
                             variant='overline'
                             fontWeight='bold'
                             fontSize='large'
                             >
                                After
                            </Typography>
                    <Box id={`source-wrapper-${index}`} sx={{width:'100%',position:'absolute' ,left:0, top:0,height:containerHeight}} className={`second-image  ${index === currentSlide ? 'show' : ''}`} >
                    <img  id={`source-${index}`} src={project.originalviewurl || project.originalimageurl} alt={project.originalviewurl || project.originalimageurl}
                    style={{height:containerHeight,objectFit:'cover'}}
                    />
                     <Typography sx={{position:'absolute',
                             top:20,
                             left:20, 
                             backgroundColor:'#6434E4', 
                             px:2,
                             py:.5, 
                             textTransform:'uppercase', 
                             color:'white',
                             borderRadius:'24px',
                             lineHeight:2
                            }}   
                             variant='overline'
                             fontWeight='bold'
                             fontSize='large'
                             >
                                Before
                            </Typography>
                     </Box>
                     <Box id={`caption-${index}`}
                     sx={{position:'absolute', 
                        bottom:0,
                        backgroundColor:'#000000a0',
                        color:'white',
                        p:2,
                        borderRadius:'0 0 8px 8px'
                     }}>
                        <Typography variant='body1' align='left' sx={{display:{sm:'block', xs:'none'}}}>
                        <b>Prompt:</b>&nbsp;{project.prompttext.slice(0, 200)}...
                        </Typography>
                        <Typography variant='body1' align='left' sx={{display:{sm:'none', xs:'block'}}}>
                            <b>Prompt:</b>{project.prompttext.slice(0, 100)}...
                        </Typography>
                     </Box>
                </div>
            ))}
        </Carousel>}
       
        </>}
        {!isReady && <>
            <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={500} height={120} />
                <Skeleton variant="rounded" width={500} height={120} />
             </Stack>
        </>}
            </Box>
        </Box>
    )
}
export default CaseStudies;