import { useState, useEffect } from 'react';

function useAspectRatio(widthRatio, heightRatio) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth/2;
      const height = (width * heightRatio) / widthRatio;
      setDimensions({ width, height });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [widthRatio, heightRatio]);

  return dimensions;
}

export default useAspectRatio;