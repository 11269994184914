import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import serverUrl from './Globals';

const firebaseConfig = {
    apiKey: "AIzaSyD7sSuOFezKjY4F2KdmoimqhChIpKvEsG8",
    authDomain: "www.typex.ai",
    // authDomain: "typexcad-cc750.firebaseapp.com",
    databaseURL: "https://typexcad-cc750-default-rtdb.firebaseio.com",
    projectId: "typexcad-cc750",
    storageBucket: "typexcad-cc750.appspot.com",
    messagingSenderId: "862526712842",
    appId: "1:862526712842:web:0c8cec1d483888c19d83f3",
    measurementId: "G-ZGZ5WHHT8G"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;

export const sendUserDataToServer = async (user) => {
    try {
      const response = await fetch(serverUrl + '/api/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
  
      if (response.ok) {
        const userData = await response.json();
        
        return userData; // Return the user data
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error(error);
    }
  };