import React, {useEffect, useState} from "react";
import { Box, Button, FormControlLabel , S} from "@mui/material";
import axios from 'axios';
import serverurl from './Globals';
import options from './template_builder.json';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';


function AdminTools ({project}) {

    const token = localStorage.getItem("accessToken"); 
    const [projectTags, setProjectTags] = useState([]);
    const [existingProjectTags, setExistingProjectTags] = useState([]);
    const [currentIsEditorial, setCurrentIsEditorial] = useState(project.isEditorial);

    const collectLeaves = (obj, targetKeys = ["options", "type"]) => {
        let leaves = [];
        for (let key in obj) {
          if (targetKeys.includes(key) && Array.isArray(obj[key])) {
            leaves = [...leaves, ...obj[key]];
          } else if (key === "type" && typeof obj[key] === 'string') {
            leaves = [...leaves, obj[key]];
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            leaves = leaves.concat(collectLeaves(obj[key]));
          }
        }
        return leaves;
      };


    const allOptions = collectLeaves(options);
   

    const addTags = async () => {
        const headers = {
            Authorization: `Bearer ${token}`,
          };
          try {
              const response = await axios.post(
                  `${serverurl}/api/project/${project.id}/addTag`, {tags:projectTags},
                  {
                  headers: headers,
                  });
              setExistingProjectTags(response.data.tags);
  
          } catch (error) {
              console.error(error);
        };
    };

    const handleOnAutocompleteChange = (newValue) => {
        setProjectTags(newValue);
        setExistingProjectTags(newValue);
    }
     
    useEffect(()=>{
        if (projectTags.length !== 0) {
            addTags();
        }
    },[projectTags]);

    useEffect(() => {
        if(project && project.tags) {
            setExistingProjectTags(project.tags);
        }
    }, [project]);


    const updateIsEditorial = async () => {
         
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        try {
            const response = await axios.post(
                `${serverurl}/api/editorial/${project.id}`, {isEditorial:!currentIsEditorial},
                {
                headers: headers,
                });
            // alert(response.data.message);
            setCurrentIsEditorial(response.data.isEditorial);

        } catch (error) {
            console.error(error);
  }};
    const handleChangeIsEditorial = (event) => {
        // const isEditorial = !currentEditorialState;
        updateIsEditorial();
    };

    const handleChange = (event)=>{
        console.log(event.target.checked);
    };
    return (
        <Box>
            <FormControlLabel
                control={<Switch
                    checked={currentIsEditorial}
                    onChange={handleChangeIsEditorial}
                    />}
                label="is Editorial"
            />
           
            <Autocomplete
                multiple
                id="tags-outlined"
                value={existingProjectTags}
                options={allOptions}
                // getOptionLabel={(option)=>{option.toString()}}
                onChange={(event, newValue) => {
                handleOnAutocompleteChange(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="tags"
                    placeholder="add more"
                />
                )}
            />
        </Box>
    );
}

export default AdminTools;