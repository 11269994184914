import React from "react";
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import heroImage from './hero.jpg';
import FbxPlayer from "./FbxPlayer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mixpanel from "./Mixpanel";
import {  useNavigate } from 'react-router-dom';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const objectUrl = "https://storage.googleapis.com/typexcad-cc750.appspot.com/uploads/e6ec232f-dca5-4be0-8740-dce54107dc8b.glb?GoogleAccessId=firebase-adminsdk-z9vob%40typexcad-cc750.iam.gserviceaccount.com&Expires=2026166400&Signature=vTmCX9PIf3p2LI5SI4O35tlSjsUnvXGGoF6iyyXoPj8mxzW4x0LcBRaHYzTfj0NutwvRnq8KPUkw%2FnLF1d6eSQj0%2Fgg9piAe6KEYTrXKV13hJZQ25eC43dZ6jbgqKEc2H6ujq%2FGtVFgKOTtyhGyD0WEGLberjSotwKEzJJYNCQRggNadpSfs%2BvMVC91oM5Qt3q4MGsPKGVHEGjAFPEYWnySLIes9vZMNdPvO2HyBxwQT4PSU5z0fJwCE1OtS5zLWy20mT5yW%2BZYINi6osDoWPJK5GRybBjUyXEP11f%2FHdQZOmQGLk1cE7YferL6qWj%2BDGGwK6K%2FF%2Bjccni0F0ONCIQ%3D%3D";

function HowTo (){

    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");

    const handleOnClick = ()=>{
        mixpanel.track('Clicked Start Free Now', {'source':'how-to'});
        navigate('/new');
    }
    const handleClickUpload = () => {
        mixpanel.track('Clicked Create New', {'source':'how-to'});
        navigate("/new");
      };
    
    return(
        <Box id='how-to-container' sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column' }}>
            <Box id='how-to-section-1' sx={{pt:8,width:'100%', flexDirection:'column',height:'330px', display:'flex',justifyContent:'center' ,alignItems:'center', 
                backgroundColor:'#020A18',
                backgroundImage: {xs:`url(${heroImage})`},
                backgroundSize: {sm:'50%', xs:'100%'},
                backgroundPosition:{sm:'calc(100% - 30px)', xs:'bottom'},
                backgroundRepeat: 'no-repeat',}}>
                <Typography variant='h3' sx={{textShadow:'1px 1px 5px black', color:'white', fontSize:{sm:'3rem', xs:'2rem'}, textAlign: 'center', px:4}}>
                    Interior Design and Architectural Rendering<br/>
                    <span style={{color:'#66DD9E', fontWeight:'bold'}}>Has Never Been Easier</span>
                </Typography>
                <Typography sx={{color:'white', mt:4, textShadow:'1px 1px 5px black'}}>
                    See How
                </Typography>
                <KeyboardArrowDownIcon sx={{color:'white', textShadow:'1px 1px 5px black'}}/>
            </Box>
            <Box id='how-to-section-2' sx={{px:{sm:4, xs:1}}}>
                <Grid container sx={{minHeight:'500px'}}> 
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                        <Box id='how-to-section-1-text' sx={{height:'100%',display:'flex',flexDirection:'column', justifyContent:'center'}}>
                            <Typography variant='h2'>
                                1.
                            </Typography>
                            <Typography variant='h6'>
                                Upload any image, sketch or 3D model.<br/>
                            </Typography>
                            <Typography variant='body1'>
                                Drag and drop, click to open your file browser or just paste a link below.
                                On your mobile device - Just click and take a photo.
                            We support most of the popular formats out there:
                                jpeg, png, webp, fbx, obj and glb<br/>
                                
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                        <Box id='how-to-section-1-image' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <img style={{objectFit:'contain', boxShadow:'4px 4px 10px rgba(0,0,0,.2)', maxWidth:'100%'}}  src='./upload_box.png' alt='upload-box'/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider variant="middle" />
            <Box id='how-to-section-3' sx={{ width:'100%', backgroundColor:'white'}}>
                <Box sx={{px:{sm:4, xs:1}}}>
                <Grid container sx={{minHeight:'500px'}}> 
                    
                    <Grid item sm={6} xs={12} sx={{p:4 , display:{sm:'block', xs:'none'}}}>
                        <Box id='how-to-section-1-image' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                        <FbxPlayer 
                            objUrl={objectUrl}
                            type={4}
                        />
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                        <Box id='how-to-section-1-text' sx={{height:'100%',display:'flex',flexDirection:'column', justifyContent:'center'}}>
                            <Typography variant='h2'>
                                2.
                            </Typography>
                            <Typography variant='h6'>
                                For 3D Models - Adjust the right camera angle<br/>
                            </Typography>
                            <Typography variant='body1'>
                             Use your mouse or fingers to rotate, zoom in or out and move:
                            </Typography>
                            <Typography>
                            1. <img src="/mouse_left.png" alt="mouse left" style={{position: 'relative', top: '7px', width:'24px'}} /> and drag or touch and swipe to rotate
                        </Typography>
                        <Typography sx={{mt:-1}}>
                            2. <img src="/pinch.png" alt="pinch" style={{position: 'relative', top: '10px', width:'32px'}} /> or <img src="/mouse_scroll.png" alt="mouse scroll" style={{position: 'relative', top: '7px', width:'24px'}} /> to zoom in and out
                        </Typography>
                        <Typography>
                            3. <img src="/mouse_left.png" alt="mouse left" style={{position: 'relative', top: '7px', width:'24px'}} /> + <b>cmd/ctrl</b> and drag or touch with two fingers and swipe to move
                        </Typography>
                        <Typography sx={{mt:1}}>
                            <b>Try it now!</b>
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4, display:{sm:'none', xs:'block'}}}>
                        <Box id='how-to-section-1-image' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                        <FbxPlayer 
                            objUrl={objectUrl}
                            type={4}
                        />
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>
            <Box id='how-to-section-4' sx={{px:{sm:4, xs:1}}}>
                <Grid container sx={{minHeight:'500px'}}> 
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                        <Box id='how-to-section-4-text' sx={{height:'100%',display:'flex',flexDirection:'column', justifyContent:'center'}}>
                            <Typography variant='h2'>
                                3.
                            </Typography>
                            <Typography variant='h6'>
                                Type in your prompt. <br/>
                            </Typography>
                            <Typography variant='body1'>
                                Writing a good prompt can be tricky sometimes. <b>No worries - we've got you covered:</b>
                                <br/>Browse through our <a href='/' target='_blank'>endless gallery</a> of beatiful renders and just copy a prompt you find relelvant 
                                <br/>- Or -<br/>
                                Use our <b>Prompt Builder</b> - in just a few clicks, we will build your prompt for optimal results. Once you're done, just click <b>Generate Renders</b> and get ready to see some magic!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                        <Box id='how-to-section-1-image' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <img style={{objectFit:'contain', boxShadow:'4px 4px 10px rgba(0,0,0,.2)', maxWidth:'100%'}}  src='./prompt.png' alt='upload-box'/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box id='how-to-section-5' sx={{ width:'100%', backgroundColor:'white'}}>
                <Box sx={{px:{sm:4, xs:1}}}>
                <Grid container sx={{minHeight:'500px'}}> 
                    
                    <Grid item sm={6} xs={12} sx={{p:4 , display:{sm:'block', xs:'none'}}}>
                        <Box id='how-to-section-5-image' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <img style={{objectFit:'contain', maxWidth:'100%'}}  src='./ready.png' alt='upload-box'/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4}}>
                    <Box id='how-to-section-5-text' sx={{height:'100%',display:'flex',flexDirection:'column', justifyContent:'center'}}>
                            <Typography variant='h2'>
                                4.
                            </Typography>
                            <Typography variant='h6'>
                                Sit back and watch the magic happen<br/>
                            </Typography>
                            <Typography variant='body1'>
                                Create endless variations by editing your prompt or generating more from a render you like
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{p:4, display:{sm:'none', xs:'block'}}}>
                        <Box id='how-to-section-5-image2' sx={{height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <img style={{objectFit:'contain', maxWidth:'100%'}}  src='./ready.png' alt='upload-box'/>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>
            <Box id='how-to-section-6' sx={{width:'100%', py:4}}>
                <Box sx={{px:{sm:4, xs:1}, height:'100%', display:'flex', flexDirection:'column' ,justifyContent:'center', alignItems:'center'}}>
                    <Typography variant='h3' sx={{textAlign:'center', fontSize:{sm:'3rem', xs:'2rem'}}}>
                    &#129668;It's that easy &#129668;
                    </Typography>
                    {!token && <Button variant='contained' color='primary' onClick={handleOnClick} sx={{mt:3}}>
                            Start Now Free
                    </Button>}
                    {token && <Button variant="contained" sx={{ mt: 3 }} onClick={handleClickUpload}>
                        <AutoAwesomeIcon/>&nbsp;&nbsp;Create New Render
                    </Button>}
                </Box>
            </Box>
            <Box id='how-to-faq' sx={{py:5, width:'100%' ,backgroundColor:'white'}}>
                <Box sx={{ display:'flex',  justifyContent:'center', alignItems:'center'}}>
                <Typography variant="h5" sx={{mb:2, textAlign:'center'}}>
                frequently asked questions
                </Typography>
                </Box>
                <Box id='faq-wrapper' sx={{mb:4, px:{sm:16, xs:4}}}>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>How many renders do I get for free?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        When you sign up you get 5 credits. Each credit is 4 renders.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <Typography>Accordion 2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    >
                    <Typography>Disabled Accordion</Typography>
                    </AccordionSummary>
                </Accordion> */}
                </Box>
            </Box>
        </Box>
    )
}

export default HowTo;