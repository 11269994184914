import React from "react";
import { Box } from "@mui/material";

function TOS (){
    return(
        <Box sx={{p:5, pt:10,pb:10, height:'100%',}}>
            <h2>Terms and Conditions</h2>
    <ol>
        <li>
            <h3>Introduction</h3>
            <p>
                By using the TypeX website and services, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services. If you do not agree with these terms, please do not use our website or services.
            </p>
        </li>
        <li>
            <h3>Account Registration, Free Trial, and Paid Tiers</h3>
            <p>
                When you register for an account on TypeX, you will be granted access to our Free Trial Tier. This tier provides you with a limited number of credits, which can be used to generate up to 4 renders for each output. The Free Trial Tier is an excellent opportunity to explore our platform and its features without any financial commitment.
            </p>
            <p>
                After the Free Trial Tier usage is exhausted, you will need to switch to either our Pay-As-You-Go (Credits) Tier or Monthly Subscription Tier to continue using our services. The Pay-As-You-Go Tier allows you to purchase credits for generating renders, providing flexibility in managing your expenses. The Monthly Subscription Tier offers a set number of credits each month for continuous access to our rendering services and cost savings compared to purchasing credits on a pay-as-you-go basis. Specific pricing details, such as the cost of credits and subscription levels, can be found on our website.
            </p>
        </li>
        <li>
            <h3>Billing</h3>
            <p>
                Your subscription will be billed on a monthly basis or as a one-time payment, depending on your chosen plan. You agree to pay all fees and charges associated with your chosen plan.
            </p>
        </li>
        <li>
            <h3>Uploaded Content and Data Storage</h3>
            <p>
                When you upload images or other content to our server, you acknowledge and agree that these files are temporarily stored on our Google Cloud server. TypeX does not guarantee the long-term storage of your uploaded files or generated outputs. Users are responsible for downloading and saving their outputs.
            </p>
        </li>
        <li>
            <h3>Outputs and Quality</h3>
            <p>
                TypeX does not guarantee the quality of the rendered images, as we utilize various AI models and APIs to provide our services. The quality of the outputs may vary depending on the input data and the AI models used. We do not accept responsibility for any dissatisfaction with the quality of the renders.
            </p>
        </li>
        <li>
            <h3>Intellectual Property Rights</h3>
            <p>
                TypeX respects the intellectual property rights of others and expects users to do the same. You must not use our services to create, upload, or share content that infringes upon the copyrights, trademarks, or other intellectual property rights of others.
            </p>
        </li>
        <li>
            <h3>Limitation of Liability</h3>
            <p>
                In no event shall TypeX or its affiliates, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, including but not limited to, damages for loss of data, profits, or goodwill.
            </p>
        </li>
        <li>
            <h3>Changes to Terms and Conditions</h3>
            <p>
                TypeX reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on our website, and by continuing to use our services after any changes have been posted, you agree to be bound by the updated Terms and Conditions.
            </p>
        </li>
        <li>
            <h3>Governing Law</h3>
            <p>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which TypeX operates. Any disputes arising from these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of that jurisdiction.
            </p>
        </li>
    </ol>
    <p>
        Please note that this is a general outline of terms and conditions, and you should consult with a legal professional to create a comprehensive and legally binding document tailored to your specific needs and jurisdiction.
    </p>
    </Box>
    )
}

export default TOS;