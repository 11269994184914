import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';
import Gallery from './Gallery';
import { UserContext } from './UserContext';
import axios from 'axios';
import serverurl from './Globals';
import EmptyGallery from './EmptyGallery';

function MyRenders({shouldShowButton}) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [personalGalleryData, setPersonalGalleryData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const token = localStorage.getItem("accessToken");


  const fetchGalleryData = async (offset) => {
      if(token){
        const headers = {
          Authorization: `Bearer ${token}`,
        };
          try {
              const response = await axios.get(`${serverurl}/api/gallery?own=true&offset=${offset}`, 
              {
                headers: headers,
              });
              if(response.data === 'no projects'){
                setIsEmpty(true);
              }
              else setPersonalGalleryData(prevData => [...prevData, ...response.data]);
              setIsLoading(false);
          } catch (error) {
              console.error(error);
              setIsLoading(false);
          }
      }
      else navigate('/login');

};

  useEffect(() => {
    fetchGalleryData(0);
  }, []);

  const handleLoadMore = () => {
    fetchGalleryData(personalGalleryData.length);
    setIsLoading(true);
  };

  return (
    <Box >
      {!isEmpty && <Gallery handleLoadMore={handleLoadMore} galleryData={personalGalleryData} isLoadingGallery={isLoading} shouldShowButton={shouldShowButton}/>}
      {isEmpty && <EmptyGallery />}
    </Box>
  );
}

export default MyRenders;