import logo from './logo.svg';
import React, {useContext, useEffect} from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './App.css';
import Main from './Main';
import { CookieConsent } from 'react-cookie-consent';
import { UserContext, UserProvider } from './UserContext';


function App() {
 
  const MyCookeisConsent = () => {
   return( <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="typex"
        style={{ background: '#eaeaea', color: '#333',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}
        buttonStyle={{ background: '#1565c0', color: '#FFF', fontSize: '13px' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. By using our website, you consent to all cookies in accordance with our <a target="_blank" href="/privacy-policy">Cookie Policy</a>.
      </CookieConsent>
   )
  } 
  

  return (
       <>
        <ThemeProvider theme={theme}>
      <UserProvider>
        <Main />
      </UserProvider>
      <MyCookeisConsent/>
      </ThemeProvider>
      </>
  );
 
}

export default App;
