import { createTheme } from '@mui/material/styles';
const theme = createTheme({

    // Other customizations...
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '18px', // Set the desired border-radius value
            },
          },
        },
      },
  });
  
  export default theme;