
import React, { createContext, useEffect, useState } from 'react';
import firebase from './firebase';
import {sendUserDataToServer} from './firebase.js';

export const UserContext = createContext({ user: null, updateUser: () => {} });



export const UserProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [authorizedUser,setAuthorizedUser] = useState(false || localStorage.getItem("accessToken"));

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
  };

  const logoutUser = () =>{
      localStorage.clear();
      setAuthorizedUser(false);
  };

  useEffect(() => {

    // firebase.auth().signInAnonymously().catch((error) => {
    //   console.error(error);
    // });
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      
        if (user) {
            setUser(user);
            user.getIdToken().then((tkn)=>{
              // set access token in session storage
              localStorage.setItem("accessToken", tkn);
              setAuthorizedUser(true);
            })
            
          const userData = await sendUserDataToServer({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          });
  
          // Update the user object with the received credits
          user.credits = userData.credits;
  
          setUser(user);
        } else {
          setUser(null);
        }
      });

      return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};