import React, { useEffect, useState } from "react";
import {  Typography, Box, Autocomplete , TextField, Grid} from '@mui/material';
import template_builder from './template_builder.json';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const comboStyle = {

}
const filledStyle = {
    color:'red',
    fontWeight:'bold'
}

function PromptCreate({onPromptTextChange, shouldDisable}){
    const [environment, setEnvironment] = useState();
    const [environmentType, setEnvironmentType] = useState();
    const [style, setStyle] = useState();
    const [lighting, setLighting] = useState();
    const [brand, setBrand] = useState();
    const [brandColor, setBrandColor] = useState();
    const [elements, setElements] = useState([]);
    const [selectedElements, setSelectedElements] = useState([]);
    const [colorScheme, setColorScheme] = useState();
    const [inspirationSource, setInspirationSource] = useState();
    const [specificDesignElements, setSpecificDesignElements] = useState();

    const cleanPromptString = (string) => {
        return string.replace(/\s+/g, " ").trim();
      };
    useEffect(() => {

        const updatedPromptString =  
        `${environment ? `Focusing on ${environment.type}` : ''}
        ${environmentType ? ` specifically the ${environmentType}.` : ''}
        ${brand && colorScheme? `The scene showcases ${brand.name} in ${colorScheme} finish,`:''}
        ${false? `contrasted against [specific element or feature].`:''}
        ${selectedElements.length!==0? `The space also includes ${selectedElements}.`:''}
        ${style || lighting || colorScheme?'Style and Detail Descriptors:':''}
        ${brand?`It should emulate the feel of an ${brand.name} catalog photo,`:''}
        ${specificDesignElements? `featuring a balance between [specific design elements].`:''}
        ${style? `The overall style should be ${style}, `:''}
        ${selectedElements.length!==0? `with a unique interplay of ${selectedElements}.`:''}
        ${colorScheme || lighting? 'Color and Lighting Descriptors:':'' }
        ${colorScheme? `Highlight the ${colorScheme},`:''}
        ${lighting? `under a ${lighting} hues lighting that brings out the unique textures and surfaces.`:''}
        ${environment ? 'The end result should be Extremely detailed, High Definition, 8k, Incredibly Detailed, High Resolution, ultra-photorealistic.':''}`
        
        onPromptTextChange(cleanPromptString(updatedPromptString));
    },[environment, environmentType, brand, brandColor, selectedElements, style, lighting, colorScheme]);
    
    const onChangeEnvironment = (event, newValue) => {
        console.log(newValue);
        if(newValue === null){
            setEnvironment(template_builder.Environment[0]);
        }
        else setEnvironment(newValue);
        
      };
      const handleEnvironmentChange = (event, newValue) => {
        const selectedEnvironment = template_builder.Environment.find(
          (env) => env.type === newValue
        );
      
        if (selectedEnvironment) {
          setEnvironment(selectedEnvironment);
          setEnvironmentType(null);
        }
      };
      

    useEffect(()=>{
        console.log(selectedElements);
    },[selectedElements]);

    useEffect(() => {
        if (environment && environmentType) {
          const selectedEnvironment = template_builder.Environment.find(
            (env) => env.type === environment.type
          );
          if (selectedEnvironment) {
                const selectedEnvironmentType = selectedEnvironment.options.find(
                     (type) => type === environmentType
            );
            if (selectedEnvironmentType) {
                const selectedElements = template_builder.Elements[environmentType];    
                setElements(selectedElements || []);
            } else {
              setElements([]);
            }
          } else {
            setElements([]);
          }
        } else {
          setElements([]);
        }
      }, [environment, environmentType]);

    return (
        <Box id='prompt-builder-container' sx={{display:'flex', alignItems:'left', flexDirection:'column' }}>
                <Box id='environment-wrapper'>
                    <ToggleButtonGroup
                        disabled={shouldDisable}
                        color="primary"
                        value={environment ? environment.type : null}
                        exclusive
                        onChange={handleEnvironmentChange}
                        aria-label="Environment"
                        sx={{mb:1.5, width:'100%'}}
                        size='small'
                        >
                        <ToggleButton sx={{width:{xs:'75%', sm:'auto'}}} value="Interior">Interior</ToggleButton>
                        <ToggleButton sx={{width:{xs:'75%', sm:'auto'}}} value="Exterior">Exterior</ToggleButton>
                        <ToggleButton sx={{width:{xs:'150%', sm:'auto'}}} value="Commercial Spaces">Commercial Spaces</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box id='environment-options-wrapper' mb={1}>
                    <Autocomplete
                        disablePortal
                        disabled={!environment}
                        id="combo-box-environmenttype"
                        options={environment && environment.options ? environment.options : []}
                        value={environmentType || null}
                        onChange={(event, newValue) => {
                            setEnvironmentType(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label={environment ? environment.options_alias : "type"}
                            />
                        )}
                    />
                </Box>
            <Grid id='style-and-lighting-wrapper' container spacing={1} mb={1}>
                <Grid id='style-wrapper' item xs={6}>  
                    <Autocomplete
                    size="small"
                    disablePortal
                    disabled={!environment}
                    id="combo-box-style"
                    options={template_builder.Style}
                    onChange={(event, newValue) => {
                        setStyle(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    sx={comboStyle}
                    renderInput={(params) => <TextField {...params} label="Style" />}
                    />
                </Grid>
                <Grid id='ligthting-wrapper' item xs={6}>
                    <Autocomplete
                    size="small"
                    disablePortal
                    disabled={!environment}
                    id="combo-box-lighting"
                    options={template_builder.Lighting}
                    onChange={(event, newValue) => {
                        setLighting(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    sx={comboStyle}
                    renderInput={(params) => <TextField {...params} label="Lighting" />}
                    />
                </Grid>
               
            </Grid>
           <Grid id='color-and-brand-wrapper' container spacing={1} mb={1}>
                <Grid id='color-wrapper' item xs={6}>
                    <Autocomplete
                    size="small"
                    disablePortal
                    disabled={!environment}
                    id="combo-box-brand-color"
                    options={template_builder.Color_scheme}
                    onChange={(event, newValue) => {
                        setColorScheme(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    sx={comboStyle}
                    renderInput={(params) => <TextField {...params} label="Color" />}
                    />
                </Grid>
                <Grid id='brand-wrapper' item xs={6}>
                    <Autocomplete
                    size="small"
                    disablePortal
                    disabled={!environment}
                    id="combo-box-brand"
                    options={template_builder.Brands}
                    onChange={(event, newValue) => {
                        setBrand(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    sx={comboStyle}
                    renderInput={(params) => <TextField {...params} label="Brand" />}
                    />
                </Grid>
            </Grid>
            <Box id='elements-options-wrapper'>
            <Autocomplete
                size="small"
                multiple
                disabled={!environment || !environmentType}
                id="tags-outlined"
                options={elements}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                    setSelectedElements(newValue);
                  }}
                // defaultValue={}
                filterSelectedOptions
                sx={{mb:2}}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={template_builder.Element}
                    placeholder="Add furniture or elements to your scene"
                />
                )}
            />
            </Box>
            {/* <Propmt style={{maxWidth:'80%'}}/> */}
        </Box>

    );
}
export default PromptCreate;