import React, { useState , useRef,useEffect,  useContext} from 'react';
import {Box, Typography, Modal, Link, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
import  styled  from '@emotion/styled';
import LinearProgress from '@mui/material/LinearProgress';
import UploadIcon from '@mui/icons-material/Upload';
import { UserContext } from './UserContext';
import FirebaseUI from './FirebaseUI'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import serverUrl from './Globals';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMediaQuery } from '@mui/material';
import mixpanel from './Mixpanel';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 0,
  outline: 'none',
  bgcolor:'white',
  width:{sm:'auto', xs:'90%'}, 
  height:{sm:'70%', xs:'70%'},
  alignItems:'center'
};

const DropZone = styled(Box)(() => ({
  border: '1px dashed #aaa',
  boxSizing: 'border-box',
  width:'100%',
  maxWidth:'100%',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '30px',
  marginBottom:'16px',
  // height: '300px',
  minWidth: '250px',
  flexGrow: 1 ,// Make the box fill the available space
  background: 'white',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,.1), 0 3px 1px -2px rgba(0,0,0,.1), 0 1px 5px 0 rgba(0,0,0,.1)',
  '&:hover': {
    background: '#fcfcfc', // Set the background color on hover
  }
}));



const FileUploader = ({ onUpload , progress, onUploadUrl, statusOfUrl}) => {

  const isMobile = useMediaQuery('(max-width: 599px)');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [filePreviews, setFilePreviews] = useState([]);
  const dropZoneRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isValidatingUrl, setIsValidatingUrl] = useState(false);
  const [link, setLink] = useState('');
  const [isUploadingFromUrl,setIsUploadingFromUrl] = useState(false);
  const FileInput = styled.input({
    display: 'none',
  });
  

  const enoughCredits = () => {
    if(user.credits<1){
      navigate('/checkout');
    return false;

    }
    else return true;
  };

  const openFileDialog = (inputRef) => {
   
    if(user === null){
      mixpanel.track('Tried to Started Upload', {
        'status':'logged out',
        'type': 'browse'
      });
      triggerSignin();
      return;
    }
    if(!enoughCredits()){
      mixpanel.track('Tried to Started Upload', {
        'status':'no credits',
        'type': 'browse'
      });
      return;
    }
    if (inputRef.current) {
      mixpanel.track('Tried to Started Upload', {
        'status':'ok',
        'type': 'browse'
      });
      inputRef.current.click();
    }
   

  };
  
  // Inside the FileUploader component
  const inputRef = useRef(null);

  const generatePreviews = (files) => {
    const previews = [];
    Array.from(files).forEach((file) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileSize = formatFileSize(file.size);
          previews.push({ url: e.target.result, name: file.name, size: fileSize });
          setFilePreviews([...previews]);
        };
        reader.readAsDataURL(file);
      } else {
        const fileSize = formatFileSize(file.size);
        previews.push({ url: '../typex.png', name: file.name, size: fileSize });
        setFilePreviews([...previews]);
      }
    });
  };

const formatFileSize = (sizeInBytes) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  let fileSize = sizeInBytes;
  let unitIndex = 0;

  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024;
    unitIndex++;
  }

  return `${fileSize.toFixed(2)} ${units[unitIndex]}`;
};
  
  const handleFilesUpload = (files) => {

    if (files.length > 0) {
        const file = files[0];
        generatePreviews([file]);
        setIsUploading(true);
        onUpload(files[0]);
    }
  };

  
  

  const triggerSignin = () => {
    console.log("rewuire login");
    // setModalIsOpen(true);
    navigate('/login');
  };


  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
        dropZoneRef.current.style.backgroundColor = 'rgba(63, 81, 181, 0.1)';
        dropZoneRef.current.style.border = '1px solid #3f51b5';
      }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target === dropZoneRef.current) {
        setIsDragging(false);
        dropZoneRef.current.style.backgroundColor = '';
        dropZoneRef.current.style.border = '';
      }
  };
 
  const handleDrop = (e) => {
    
    if(user === null){
      mixpanel.track('Tried to Upload', {
        'status':'logged out',
        'type': 'drag n drop'
      });
      triggerSignin();
      setIsDragging(false);
      dropZoneRef.current.style.backgroundColor = '';
      dropZoneRef.current.style.border = '';
      return;
    }
    if(!enoughCredits()){
      mixpanel.track('Tried to Upload', {
        'status':'no credits',
        'type': 'drag n drop'
      });
      return;
    }
    mixpanel.track('Tried to Upload', {
      'status':'ok',
      'type': 'drag n drop'
    });
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dropZoneRef.current.style.backgroundColor = '';
    dropZoneRef.current.style.border = '';
    const files = e.dataTransfer.files;
    if (files.length) {
        handleFilesUpload(files);
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
};

const InputLink = ()=> {

  const handleInputLinkOnChange = (event) => {
    setLink(event.target.value);
    if(isValidatingUrl)
      setIsValidatingUrl(false);
  };
  const handleLinkUploadClick = ()=>{
    if(link){
      setIsValidatingUrl(true);
      mixpanel.track('Tried to Upload', {
        'status':'ok',
        'type': 'URL'
      });
      onUploadUrl(link);
    }
  };
  
  useEffect(() => {
    if (statusOfUrl) {
      setIsUploadingFromUrl(true);
    }
  }, [statusOfUrl]);  


  return (
    <Box sx={{width:'100%'}}>
    <Paper
    component="form"
    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' , boxSizing:'border-box', borderRadius:'8px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,.1), 0 3px 1px -2px rgba(0,0,0,.1), 0 1px 5px 0 rgba(0,0,0,.1)',
  }}
    >
      <InputBase value={link}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Paste a link to an image"
        inputProps={{ 'aria-label': 'paste a link' }}
        onChange={handleInputLinkOnChange}
       />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} onClick={handleLinkUploadClick}>
      {!isUploadingFromUrl ? <UploadIcon /> : <CircularProgress size={24}/>}
     </IconButton> 
     
  </Paper>
  {(!statusOfUrl && isValidatingUrl) && <Typography variant='caption' color='error'>Url must point to a supported file type</Typography>}
  {/* <Box sx={{ justifyContent: 'center',pointerEvents: 'none' }}>
  <LinearProgress variant="determinate" value={progressFromUrl} sx={{height:'2px'}}/>
  </Box> */}
  </Box>
  );
}
  
  return (
    <Box sx={{display: 'flex', width:{sm:'85%', xs:'100%'},
    justifyContent: 'center',
    alignItems: 'center', flexDirection:'column', textAlign:'center', mt:{xs:5, sm:0}}}>
       
        <DropZone
            ref={dropZoneRef}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ pointerEvents: isUploading ? 'none' : 'auto' }}
            onClick={!isUploading ? () => openFileDialog(inputRef) : undefined}
            >
            <FileInput
            ref={inputRef}
            type="file"
            multiple
            accept="image/*,.fbx,.obj, .glb"
            onChange={(e) => handleFilesUpload(e.target.files)}
            
            />
            
            {!isUploading && <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent:'center',pointerEvents: 'none', color: (user && enoughCredits())? '':'#ccc'}} >
                        <Typography variant="h6" sx={{mb:1}}>
                          Upload a file
                        </Typography>
                        <Typography variant="subtitle1" sx={{textAlign:'center',pointerEvents: 'none', mb:1}}>
                          {isMobile? 'Tap to browse': (isDragging
                          ? <>Drop file here</>
                          : <>Drag and drop file here or <Link underline='none'>click to browse</Link></>
                          )}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center',pointerEvents: 'none' }}>
                                <UploadIcon sx={{ mb: 1, fontSize:'2.5rem' }} />
                            </Box>
                            <Typography variant="body2">
                              Supported files - jpg, jpeg, png, OBJ, FBX, GLB
                            </Typography>                         
                  </Box>
            }
            <Box id='file-preview' sx={{flexGrow: 1, pointerEvents: 'none'}}>
                {filePreviews.map((filePreview, index) => (     
                        // <Box key={index} sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Grid container spacing={2} key={index}>
                           {filePreview.url  &&
                            <Grid item xs={4}>
                            <Box sx={{width:'100%', height:'80px'}}>
                                <img src={filePreview.url} alt={filePreview.name} style={{width:'100%', height:'100%',objectFit:'cover'}} />
                            </Box>
                          </Grid>
                              }
                          <Grid item xs={8}>
                            <Typography variant='subtitle1' sx={{textAlign:'left', width:'100%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                              {filePreview.name}
                            </Typography>
                            <Box sx={{width:'100%',  textAlign: 'left'}}>
                              <Typography variant="caption">
                                Size: {filePreview.size}
                              </Typography>
                            </Box>
                          </Grid>
                          </Grid>
                        // </Box>
                  ))}
              </Box>
              
              {isUploading && <Box sx={{ justifyContent: 'center',pointerEvents: 'none' }}>
               
                <LinearProgress variant="determinate" value={progress} sx={{height:'4px', mt:2}}/>

              </Box>
            
                }
        </DropZone>
        {!isUploading && <>
          <Typography sx={{mb:2}}>Or</Typography>
          <InputLink />
          </>
        }
        <Modal open={modalIsOpen} onClose={handleClose}>
          <Box sx={style}>
            <Box>
              <FirebaseUI title="Please Sign in or Sign up to upload" />
            </Box>
          </Box>
        </Modal>
  </Box>
  );
};

export default FileUploader;