import React, {useRef, useState, useContext, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import FileUploader from "./FileUploader";
import {Chip, Collapse, Box, TextField, Typography, Button, Grid, Snackbar, SnackbarContent, Alert, Link, CircularProgress, ButtonBase} from '@mui/material';
import FbxPlayer from "./FbxPlayer";
import serverurl from "./Globals";
import axios from 'axios';
import { UserContext } from "./UserContext";
import PromptCreate from "./PromptCreate";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LoadingButton from '@mui/lab/LoadingButton';
import mixpanel from './Mixpanel';
import { ReactSketchCanvas } from 'react-sketch-canvas';



function NewProject(){
    
    const [file, setFile] = useState(null);
    const [originalFileType, setOriginalFileType] = useState(null);
    const [original3DfileUrl, setOriginal3DfileUrl] = useState(null);
    const [promptText, setPromptText] = useState('');
    const [negativePromptText, setNegativePromptText] = useState('');
    const [triggerUseView, setTriggerUseView] = useState(false);
    const [progress, setProgress] = useState(0);
    const [generateRenderClicked, setGenerateRenderClicked] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [failSnackbarOpen, setFailSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user, updateUser } = useContext(UserContext);
    const [isValidUrl, setIsValidUrl] = useState(false);
    const token = localStorage.getItem("accessToken");
    const navigate = useNavigate();
    const [showPromptCreate, setShowPromptCreate] = useState(false);
    const [promptValidationError, setPromptValidationError] = useState(false);
    const [paintingData, setPaintingData] = useState(null);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const imageRef = React.createRef();
    const maskCanvasRef = React.useRef(null);

    useEffect(()=>{
      mixpanel.track('Viewed Page', {
        'page_name':'new project',
      });
  },[]);

    const handleTextFieldChange = (event) => {
        setPromptText(event.target.value);
      };

    const handleNegativeTextFieldChange = (event) => {
         setNegativePromptText(event.target.value);
    };
      
    const handleFileUploadAxios = (file) => {
      
      mixpanel.track('Started Uploading',{
        'source':'file uploader'
      });
        const formData = new FormData();
        formData.append('file', file);
       
        axios.post(serverurl+'/api/upload', formData, {
        onUploadProgress: (progressEvent) => {
            const percentage =Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentage);
            
        },
        })
        .then(response => {
            handleUploadSuccess(response.data);
        })
        .catch(error => console.error(error));
    };

    
   // upload from 3D viewer
    const handleFileUploadPerspective = (formData) => {   
      
      setOriginalFileType(file.type);
        formData.append('originalFile', file.url);
        fetch(serverurl+'/api/upload', {
          method: 'POST',
          body: formData
        })
        .then(response => response.text())
        .then(data => {
            handleUploadPerspectiveSuccess(JSON.parse(data));
        })
        .catch(error => console.error(error));
    };

    const handleLinkInput = (url) => {
      try {
        setIsValidUrl(true);
          const parsedUrl = new URL(url);
          const filePath = parsedUrl.pathname;
          const fileUrlPattern = /[/|.|\w|\s|-]*\.(?:jpg|jpeg|png|obj|fbx|glb)/gi;
  
          if (filePath && fileUrlPattern.test(filePath)) {
              mixpanel.track('Started Uploading',{
                'source':'URL'
              });
              // Continue with your existing code...
              axios.post(`${serverurl}/api/upload`, null, {
                  params: {
                    file_url: url,
                  }
              })
              .then(response => {
                
                handleUploadSuccess(response.data);
              })
              .catch(error => {
                console.error(error); // Handle any errors
              });
          } else {
              setIsValidUrl(false);
          }
      } catch (error) {
          setIsValidUrl(false);
        
      }
  };
  

    const sendFile = (file, is3D) => {
        
        const token = localStorage.getItem("accessToken");

        setIsLoading(true);
        fetch(serverurl+'/api/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ 
            "url" : file.url,
            "thumbnailUrl": file.thumbnailUrl,
            "viewUrl":file.viewUrl,
            "promptText": promptText,
            "negative": negativePromptText, 
            "is3D":is3D,
            "original3DFileUrl" : original3DfileUrl,
            "fileType":originalFileType
           })
        })
        .then(response => response.text())
        .then(data => sendToAstriaSucces(JSON.parse(data)))
        .catch(error => sendToAstriaError(error));
      };

    const sendToAstriaSucces = (data) => {
        setIsLoading(false);
        if(data.id){
          mixpanel.track('Generate Renders Success');
            handleSuccessSnackbarOpen();
            const updatedUser = {
                ...user,
                credits: user.credits - 1,
              };
            //updateUser(updatedUser);
            navigate("/gallery/"+data.id);
        }
        else {
            handleErrorInSend();
        }

    };
    const handleErrorInSend = () => {
      mixpanel.track('Generate Renders Error');
        setIsLoading(false);
        handleFailSnackbarOpen();
    }
    const sendToAstriaError = (error) => {
      mixpanel.track('Generate Renders Astria Error');
        console.log(error)
    }
    const handleUploadSuccess = (fileObject) =>{
      mixpanel.track('Upload success');
        setFile(fileObject);
        if(fileObject.type !==1)
          setOriginal3DfileUrl(fileObject.url);
          setOriginalFileType(fileObject.type);
      };

    const handleUploadPerspectiveSuccess = (fileObject) =>{
        sendFile(fileObject, true);
    };

    const handleSaveView = () => {
        setTriggerUseView(true);
    };

    const handleUseViewFunctionComplete = () => {
        setTriggerUseView(false);
    };

    useEffect(()=>{
      if (promptValidationError)
          setPromptValidationError(false);
    }, [promptText]);

    const handleSendButtonClick = () => {
      mixpanel.track('Clicked Generate Renders');

        setGenerateRenderClicked(true);
        if (promptText.length < 30) {
          // Show error message when prompt is less than 30 characters
          setPromptValidationError(true);
          return;
        } else {
          // Continue with generating renders
          setPromptValidationError(false);
          if (file.type === 1) {
            sendFile(file, false);
          } else if (file.type !== 5) {
            handleSaveView();
          }
        }
        setGenerateRenderClicked(false);
      };

    const handlePromptTextChange = (newPromptText) => {
        setPromptText(newPromptText);
      };

    const handleSuccessSnackbarOpen = () => {
        setSuccessSnackbarOpen(true);
      };
      
    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
      };

      const handleFailSnackbarOpen = () => {
        setFailSnackbarOpen(true);
      };
      
    const handleFailSnackbarClose = () => {
        setFailSnackbarOpen(false);
      };

    const handleShowPromptCreate = () => {
        if(!showPromptCreate) 
            setShowPromptCreate(true);
        else  setShowPromptCreate(false);
  };
  
  ////// TESTING BRUSH ON IMAGE ///////
  const handleImageLoad = (event) => {
    setImageDimensions({ 
      width: event.target.clientWidth, 
      height: event.target.clientHeight 
    });
  };

  const updateDimensions = () => {
    if (imageRef.current) {
      setImageDimensions({
        width: imageRef.current.clientWidth,
        height: imageRef.current.clientHeight,
      });
    }
  };

  const getImageSize = async () => {

  };
  const exportMask = async () => {
    maskCanvasRef.current.exportImage("png").then((data) => {
      // Create new Image object
      const img = new Image();
      img.src = data;

      const originalWidth = imageRef.current.naturalWidth;
      const originalHeight = imageRef.current.naturalHeight;
  
      img.onload = () => {
        // Create a new canvas
        const newCanvas = document.createElement('canvas');
        const ctx = newCanvas.getContext('2d');
  
        // const originalWidth = img.naturalWidth;
        // const originalHeight = img.naturalHeight;
        // Set the dimensions of the canvas to match the original image
        newCanvas.width = originalWidth;
        newCanvas.height = originalHeight;
  
        // Draw the sketch onto the new canvas, scaling it to fit
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight);
  
        // Export the new canvas as a data URL
        const resizedData = newCanvas.toDataURL('image/png');
  
        // Download the image as before
        const link = document.createElement('a');
        link.href = resizedData;
        link.download = 'canvasImage.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    }).catch((e) => {
      console.log(e);
    });
  };
  
  useEffect(() => {
    // Update dimensions immediately in case image has already been loaded
    updateDimensions();
    
    // Add event listener for resize
    window.addEventListener("resize", updateDimensions);
  
    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []); 
/////// END OF TESTING BRUSH ON IMAGE///////

    const SuccessSnackBar = () => {

        return (
            <Snackbar
                    open={successSnackbarOpen}
                    autoHideDuration={2000}
                    onClose={handleSuccessSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                    <SnackbarContent
                        message="Sending for render"
                        style={{ backgroundColor: 'green' }}
                    />
            </Snackbar>
        );
    }
    const FailSnackBar = () => {

        return (
            <Snackbar
                    open={failSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleFailSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                    <SnackbarContent
                        message={
                          <span>Failed to send file, please make sure you write a meaningful prompt and try again.
                        <br/>If this issue persist, please contact ai@typex.ai</span>}
                        style={{ backgroundColor: 'red' }}
                    />
            </Snackbar>
        );
    }

    return(
        <>
        
        <Box id="new-project-wrapper" style={{height:'100vh', display:'flex', alignItems:'center',justifyContent: 'center'}} >
        {/* {isLoading && <CircularProgress sx={{position:'absolute', zIndex:'100'}} />} */}
        {user && user.credits<1 && <Alert severity="info" sx={{position:'absolute', width:'70vw', top:'80px', margin:'auto'}}>
            Looks like you're out of credits. No worries, <Link href="/checkout">buy more now!</Link>
            </Alert>}
        {!token && <Alert severity="info" sx={{position:'absolute', width:'70vw', top:'80px', margin:'auto'}}>
        Please <Link href="/login">log in or sign up</Link> before uploading a file
        </Alert>}
            
            <Box id="viewer-wrapper" sx={{display:'flex', alignItems:'center', height:{xs: '100%' ,sm:'100%'}, width:'100%'}}>
                <Grid id="outer-grid"spacing={2} container sx={{m:0, height:'100%' }}>
                {file === null && <Grid id="file-upload-grid" item xs={12} sm={4} sx={{p:2, width:'100%',display:'flex', alignItems:'center', background:'#eaeaea', justifyContent:'center'}}> 
                 <FileUploader onUpload={handleFileUploadAxios} progress={progress} onUploadUrl={handleLinkInput} statusOfUrl={isValidUrl}/>
                </Grid>}
                {file!=null && <><Grid id="fbx-viewer-grid" xs={12} sm={4} item sx={{p:2, height:{xs:'60%', sm:'100%'}, width:'100%',display:'flex', alignItems:'center', background:'#eaeaea'}} >
                        
                        {file.type ===1 && 
                        <Box id="original-image-container" className="temp" sx={{position:'relative',display:'flex',  justifyContent:'center', width:'100%', maxHeight:'70%'}}>
                            <img src={file.viewUrl? file.viewUrl:file.url} alt={file.url} width="100%" style={{objectFit:'contain'}}/>
                            {/* <ReactSketchCanvas
                              ref={maskCanvasRef}
                              backgroundImage={file.viewUrl ? file.viewUrl : file.url}
                              value={paintingData}
                              onChange={setPaintingData}
                              strokeWidth={10}
                              strokeColor="#6434E488"
                              style={{ 
                                width: imageDimensions.width, 
                                height: imageDimensions.height, 
                                position:'absolute', top:0, left:0}}
                            />
                            <Button onClick={exportMask}>export mask</Button> */}
                        </Box>
                        }
                        
                        {(file.type === 2 || file.type ===3 || file.type===4) &&
                            <Box>
                            <FbxPlayer 
                                onUploadPerspective={handleFileUploadPerspective} 
                                triggerUseView={triggerUseView} 
                                onCompleteUseView={handleUseViewFunctionComplete}
                                objUrl={file.url}
                                type={file.type}
                                />
                            </Box>
                         }
                    </Grid></>}
                    <Grid id="prompt-grid" xs={12} sm={8} item sx={{ p:2, height:{xs:'100%', sm:'100%'}, display:'flex', alignItems:{sm:'center', xs:'flex-start'}}} >
                       
                        <Box sx={{px:{sm:2, xs:0}, display:'flex', width:'100%', flexDirection:'column'}}>
                           {file!==null &&  <Box sx={{ display: 'flex', justifyContent:{sm: 'flex-start', xs:'center'}, mb:4}}>
                           <Typography variant="body2" sx={{display:{sm:'block', xs:'none'}}}>
                                   New to writing prompts?&nbsp;
                                </Typography>
                            <ButtonBase  onClick={handleShowPromptCreate}>
                                <Typography variant="body2" >
                                    <Link underline='none'>{!showPromptCreate? 'Try our prompt builder':'Close prompt builder'}</Link>
                                </Typography>
                               
                            </ButtonBase>
                            <Typography variant="body2" sx={{display:{sm:'block', xs:'none'}}} >
                                 &nbsp;or get some&nbsp;<Link underline='none' target="_blank" href={'/?source=prompt_builder'}>inspiration</Link>
                                </Typography>
                            {/* <Chip label="beta" size='small' color='secondary' sx={{px:1}}/> */}
                            </Box>}
                            <Collapse in={showPromptCreate}>
                                <Box>
                                <PromptCreate onPromptTextChange={handlePromptTextChange} shouldDisable={file===null?true:false}/>
                                </Box>
                            </Collapse>
                            
                            {/* <Typography variant="caption" mb={2} color={file===null?'#CCC':''} sx={{display:{sm:'block', xs:'none'}}}>Type a detailed description of your render</Typography> */}
                            <TextField 
                            style={{width:'100%'}} 
                            id="outlined-multiline-static"
                            label="Describe your desired render"
                            multiline
                            minRows={3}
                            disabled={file===null? true:false}
                            placeholder={"A residential building made of... The overall style should be... with direct lighting...,  "}
                            value={promptText}
                            onChange={handleTextFieldChange}
                            onBlur={()=>{setPromptValidationError(false)}}
                            InputLabelProps={{
                                shrink: true,
                              }}
                              error={promptValidationError}
                              helperText={
                                (promptValidationError && "Please enter a description with at least 30 characters.")
                              }
                            />
                            <TextField id="negative-prompt" label="Negative prompt" variant="outlined"
                            disabled={file===null? true:false}
                            placeholder={"ugly, boring, blurry, pixelated, trees"}
                            onChange={handleNegativeTextFieldChange}
                            onBlur={()=>{
                                setPromptValidationError(false)
                            }}
                            InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{mt:2}}
                            />
                            {/* <Box mt={2}>
                                <Button disabled={file===null} sx={{width:'100%'}} variant="contained" onClick={handleSendButtonClick}>Generate Renders</Button>
                            </Box> */}
                            <Box
                              id="generate-renders-wrapper"
                              mt={2}
                              
                              >
                              <LoadingButton loadingPosition="start"
                                              startIcon={<AutoAwesomeIcon />} 
                                              disabled={file===null}
                                              loading={isLoading}
                                              variant="contained" 
                                              onClick={handleSendButtonClick}
                                              sx={{width:'100%', height:{xs:'56px', sm:''}, borderRadius:{xs:'28px', sm:''}}}
                                              >
                                  <span>Generate Renders</span>
                              </LoadingButton>
                          </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SuccessSnackBar />
            <FailSnackBar />
        </Box>
        
        
        </>
    );
}
export default NewProject