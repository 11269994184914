import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Avatar, Typography, AppBar, Toolbar, ButtonBase, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import firebase from './firebase';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ProductionQuantityLimitsSharp } from '@mui/icons-material';


function MyAppBar(props) {
    const { user , logoutUser} = useContext(UserContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState();
    const token = localStorage.getItem("accessToken");
    const isMenuOpen = Boolean(anchorEl);
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [currentGallery, setCurrentGallery] = useState();

    
    const pages = ['Products', 'Pricing', 'Blog'];
    const routes = [
      { path: '/', label: 'Get inspired' },
      { path: '/renders', label: 'My Renders' },
      { path: '/how-to', label: 'How to'}
    ];

    useEffect(()=>{
      const { pathname } = location;
      if(pathname === '/')
        setCurrentGallery(routes[0].label);
      if(pathname === '/renders')
        setCurrentGallery(routes[1].label);
      if(pathname === '/how-to')
        setCurrentGallery(routes[2].label);
    },[location]);


    useEffect(()=>{
      if(user && token){
        setIsAuthenticated(true)
      }
    },[user]);


    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.pageYOffset > 480 );
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
    const handleLogoClick = () => {
        
        navigate("/");
    }

    const handleLogout = () => {
      handleMenuClose();
      firebase.auth().signOut().then(() => {
        setIsAuthenticated(false);
        logoutUser();
        navigate('/');
      }).catch((error) => {
        // An error happened.
      });
    };

    const handleLogin = () => {
      localStorage.setItem('prevUrl', window.location.href);
      navigate("/login");
    };

    const handleBuyCreditsClick = () => {
      setAnchorEl(null);
      navigate('/checkout');
      
    };
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
         {token && user && [
         <Box key="profile" sx={{pl:2, pt:1, pb:1}}>
          <Typography variant='subtitle2' sx={{mb:-.8}}>{user.displayName}</Typography>
          <Typography variant='caption' >{user.email}</Typography>
         </Box>,
         <MenuItem key="credits">
         <Typography variant='caption'> <b>{user.credits}</b> Credits</Typography>
         <Button variant='outlined' color='secondary' sx={{ml:1}} onClick={handleBuyCreditsClick}>
          <Typography variant='caption' sx={{fontSize:10 }}>Buy Credits</Typography>
          </Button>
         </MenuItem>
         ]}
          <MenuItem onClick={handleLogout}>
          <Typography variant='caption'>Logout</Typography>
          </MenuItem>
       
      </Menu>
    );
    
    const renderMainMenu = (
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}> 
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
             sx={{color:(!token && !isScrolled && location.pathname === '/' || (location.pathname==='/how-to' && !isScrolled))?'white':'inherit'}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {routes.filter((route) => route.path !== '/renders' || isAuthenticated)
                         .map((route) => (
                <MenuItem key={route.path} onClick={handleCloseNavMenu}>
                    <ButtonBase
                          key={route.path}
                          sx={{ mr: 2, "&:hover": { color: '#5F8DF7' } }}
                          component={NavLink}
                          to={route.path}
                          >
                          <Typography variant="body2">{route.label}</Typography>
                      </ButtonBase>
                </MenuItem>
              ))}
            </Menu>
          </Box>

    );
 

    return(
      <Box>
        {/* <AppBar component="nav" sx={{backgroundColor:'#222'}}> */}
        
        <AppBar component="nav" style={{ background: 'transparent', boxShadow: 'none', color:'#222'}}>
        {/* <Box sx={{width:'100vw', height:'50px', background:'#6434E4'}}>
          this is a box
        </Box> */}
        {(location.pathname === '/' || location.pathname==='/how-to') && !token && <Box id='top-announcement-container' sx={{display:'flex',height:'40px', borderRadius:0, backgroundColor:'#EB597A', color:'white', justifyContent:'center', alignItems:'center'}}>
          <Box id='top-announcement-content'>
          <Typography>
          &#128640; Early birds <b>bonus</b> - First 5 renders are free! &#128640;
          </Typography>
          </Box>
        </Box>}
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {renderMainMenu}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ButtonBase sx={{ cursor: 'pointer', display: 'flex' }} onClick={handleLogoClick}>
                  <img src="../typex.png" alt="TypeX Logo" style={{ height: '40px' }} />
                </ButtonBase>
              </Box>
                  <Box id='top-nav-items' sx={{display:{sm:'flex', xs:'none'},ml:2, flexGrow:1}}>
                  {routes.filter((route) => route.path !== '/renders' || isAuthenticated)
                         .map((route) => (
                        <ButtonBase
                          key={route.path}
                          sx={{ mr: 0, "&:hover": { color: '#5F8DF7' } , p:1, 
                          color:(currentGallery === route.label)?'primary.dark':'',
                          borderRadius:1
                        }}
                          component={NavLink}
                          to={route.path}
                          >
                          <Typography variant="body2" sx={{color:(!token && !isScrolled && location.pathname === '/' || (location.pathname==='/how-to' && !isScrolled))?'white':'inherit'}}>{route.label}</Typography>
                        </ButtonBase>
                        ))}
                  </Box>
                  <Box sx={{display:'flex'}}>
                    {/* {token && user && user.displayName && (<> */}
                      {isAuthenticated && (<>
                     
                      <ButtonBase onClick={handleProfileMenuOpen}>
                            {/* <MoreVertIcon /> */}
                            <Avatar sx={{width:'28px', height:'28px'}} src={user.photoURL} alt={user.displayName} />
                      </ButtonBase>
                      </>
                    )}
                    {token==null &&<ButtonBase onClick={handleLogin}>
                       <Typography sx={{lineHeight:'1.8rem', color:(!isScrolled && (location.pathname === '/' || location.pathname==='/how-to' ))?'white':'inherit'}} variant="body2">Login</Typography>
                    </ButtonBase>}
                  </Box>
              </Toolbar>
        </AppBar>
          
          {renderMenu}
          </Box>
    )
};

export default MyAppBar;