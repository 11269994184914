import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button, Typography } from '@mui/material';
import Gallery from './Gallery';
import { UserContext } from './UserContext';
import axios from 'axios';
import serverurl from './Globals';
import Hero from './Hero';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




function AllProjects({shouldShowButton}) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [allGalleryData, setAllGalleryData] = useState([]);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
   
    fetchGalleryData(0);
  }, [token]);


  const fetchGalleryData = async (offset) => {
    if(token){
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios.get(`${serverurl}/api/gallery/?all=true&offset=${offset}`,
          {
              headers: headers,
          });
          if(response.data === 'no permission')
            navigate('/');
          else setAllGalleryData(prevData => [...prevData, ...response.data]);
        setIsLoading(false);
    } catch (error) {
        console.error(error);
        setIsLoading(false);
    }
  }
  else navigate('/');
};

  const handleLoadMore = () => {
    fetchGalleryData(allGalleryData.length);
    setIsLoading(true);
  };

  return (
    <Box >
      <Gallery handleLoadMore={handleLoadMore} isAdmin={true} galleryData={allGalleryData} isLoadingGallery={isLoading} shouldShowButton={token?true:false}/>
    </Box>
  );
}

export default AllProjects;