import { Typography, Box } from "@mui/material";
import React from "react";


function Footer(){
    const currentYear = new Date().getFullYear();
    return (
        <Box sx={{px:4, py:2, position:'fixed', bottom:'0px', display:{sm:'block', xs:'none'}}}>
            <Typography variant="caption">
                © {currentYear} TypeX. All rights reserved.
            </Typography>
        </Box>
    )
}
export default Footer;