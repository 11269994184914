import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button, Typography } from '@mui/material';
import Gallery from './Gallery';
import { UserContext } from './UserContext';
import axios from 'axios';
import serverurl from './Globals';
import Hero from './Hero';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mixpanel from './Mixpanel';
import CaseStudies from './CaseStudies';


function EditorialGallery({shouldShowButton}) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [editorialGalleryData, seteditorialGalleryData] = useState([]);
  const token = localStorage.getItem("accessToken");



 
    mixpanel.track('Viewed Page', {
      'page_name':'homepage',
    });
 
  const fetchGalleryData = async (offset) => {
        
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios.get(`${serverurl}/api/gallery?editorial=true&offset=${offset}`,
                                  {
                                    headers: headers,
                                  });
        seteditorialGalleryData(prevData => [...prevData, ...response.data]);
        setIsLoading(false);
    } catch (error) {
        console.error(error);
        setIsLoading(false);
}};
  useEffect(() => {
   
    fetchGalleryData(0);
  }, []);

  const handleLoadMore = () => {
    fetchGalleryData(editorialGalleryData.length);
    setIsLoading(true);
  }

  return (
    <Box >
      {!token && <>
        <Hero/>
        <CaseStudies gallery={editorialGalleryData}/>
        <Box sx={{position:'relative', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Typography variant="h5" sx={{pt:6, display:{xs:'none', sm:'block'}}}>
          Get some inspiration
        </Typography>
        <ExpandMoreIcon fontSize='large' sx={{ position:'absolute', top:'20px', display:{xs:'block', sm:'none'}}}/>
        </Box>
      </>}
      <Gallery handleLoadMore={handleLoadMore} galleryData={editorialGalleryData} isLoadingGallery={isLoading} shouldShowButton={token?true:false}/>
    </Box>
  );
}

export default EditorialGallery;