import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import heroImage from './hero.jpg'
import {  useNavigate } from 'react-router-dom';
import mixpanel from "./Mixpanel";


function Hero(){

    const navigate = useNavigate();

    const handleOnClick = ()=>{
        mixpanel.track('Clicked Start Free Now', {'source':'hero'});
        navigate('/new');
    }
    return (
        // <Box id='hero-wrapper' sx={{ backgroundImage: 'linear-gradient(to right,#EB5979, #6B31ED)'
        // ,width:'100%', display:'flex', height:'50vh', justifyContent:'center', alignItems:'center'}}>
            <Box id='hero-wrapper' sx={{
                backgroundColor:'#020A18',
                // backgroundImage: {xs:`url(${heroImage})`},
                // backgroundSize: {sm:'50%', xs:'100%'},
                // backgroundPosition:{sm:'calc(100% - 30px)', xs:'bottom'},
                
                // backgroundRepeat: 'no-repeat',
                width:'100%', 
                display:'flex',
                height:'420px',
                justifyContent:'center',
                alignItems:'center'}}>
           {/* <Grid container>
                <Grid item sm={6}>
                    <Box sx={{display:'flex', flexDirection:'row'}}>
                            Hero left
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box sx={{display:'flex', flexDirection:'row'}}>
                            Hero right
                    </Box>
                </Grid>
           </Grid> */}
           <Box id='hero-body' sx={{background:{sm:'#0000', xs:'#0004'}, px:6,width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', color:'white'}}>
                <Box sx={{width:{sm:'100%', xs:'100%'}, textAlign:'center', pt:6}}>
                    <Typography variant='h2' sx={{display:{xs:'block', sm:'block'}, fontWeight:'bold', fontSize:{sm:'60px', xs:'40px'}}}>Instantly Render Your Model With AI</Typography>
                    {/* <Typography variant='h3' sx={{display:{xs:'block', sm:'none', fontWeight:'bold'}}}>typex.ai</Typography> */}
                    <Typography variant='body1' sx={{mt:1, display:{sm:'block', xs:'none'}}}>Upload a photo, 3D sketch, or drawing and reimagine your space with our innovative AI platform. <br/>Early adopters are treated to free renders!</Typography>
                    <Button variant='contained' color='secondary' onClick={handleOnClick} sx={{mt:3}}>
                        Start Now Free
                    </Button>
                </Box>
               
           </Box>
        </Box>
    )
}

export default Hero