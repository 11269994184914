import React, { useEffect, useState, useRef, useCallback , useContext} from 'react';
import { useMatch , useNavigate } from 'react-router-dom';
import {  Grid , Modal, Box, Menu, MenuItem, TextField} from '@mui/material';
import {  Card, CardMedia, Button, Typography, IconButton, Link} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { throttle } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleLeft from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar, SnackbarContent } from '@mui/material';
import serverurl from './Globals';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Helmet } from 'react-helmet';
import { UserContext } from "./UserContext";
import firebase from 'firebase/compat/app';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IosShareIcon from '@mui/icons-material/IosShare';
import mixpanel from './Mixpanel';
import moment from 'moment';

import FbxPlayer from './FbxPlayer';
import Brightness1Icon from '@mui/icons-material/Brightness1';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 0,
    outline: 'none'
  };

 const isShareSupported = !!navigator.share;

function Project({galleryData}) {
    const token = localStorage.getItem("accessToken");
    const navigate = useNavigate();
    const [relevantGallery, setrelevantGallery] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const [selectedImgUrl, setSelectedImgUrl] = useState('');
    const [selectedImg, setSelectedImg] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isProcessingRender, setIsProessingRender] = useState(false);
    const [showFullText, setShowFullText] = useState(false)
    // const [galleryImageViewed, setGalleryImageViewed] = useState(1);
    const [projectId, setProjectId] = useState('');
    const [failSnackbarOpen, setFailSnackbarOpen] = useState(false);
    const [isProcessingMore, setIsProcessingMore] = useState(false);
    const [timelineLength, setTimelineLength] = useState();
    const { user } = useContext(UserContext);
    const [triggerUseView, setTriggerUseView] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [editedPrompt, setEditedPrompt] = useState('');
    const [isMobileFullScreen, setIsMobileFullScreen] = useState(false);
    const [touchStartPosition, setTouchStartPosition] = useState(null);
    const [touchCurrentPosition, setTouchCurrentPosition] = useState(null);
    const match = useMatch('/gallery/:id');
    const { id } = match.params;

    useEffect(()=>{
        mixpanel.track('Viewed Page', {
            'page_name':'project page',
            'project id': id
        });
    }, []);


    useEffect(() => {
        if(id){
            galleryData.forEach(element => {
                if(element.id === id){
                    setrelevantGallery(element);     
                }
            });
        }   
    }, [galleryData]);

    useEffect(() => {
        let timelineLength;
        if(relevantGallery && relevantGallery.timeline)
             timelineLength = relevantGallery.timeline.length;
        if(relevantGallery && relevantGallery.prompttext && !relevantGallery.timeline[timelineLength-1].isReady){
            const tempImg = relevantGallery.originalimageurl;
            
            const updatedImages = relevantGallery.images ? [...relevantGallery.images] : [];
            updatedImages.push(tempImg, tempImg, tempImg, tempImg);

            const lastTimelineIndex = relevantGallery.timeline.length - 1;
            const lastTimelineItem = relevantGallery.timeline[lastTimelineIndex];
            
            const updatedGallery = {
                ...relevantGallery,
                images: updatedImages,
              };
    
              const updatedTimeline = [
                ...relevantGallery.timeline.slice(0, lastTimelineIndex), // Keep the existing timeline items
                {
                  ...lastTimelineItem,
                  images: [tempImg,tempImg,tempImg,tempImg],
                },
              ];

            const updatedRelevantGallery = {
                ...updatedGallery,
                timeline: updatedTimeline,
              };


            setrelevantGallery(updatedRelevantGallery);
            setIsProessingRender(true);
            const projectId = id
        
            const pollingInterval = 10000; // 10 seconds

            const pollIsReady = setInterval(() => {
                // Make API call to check the value of isReady
              
                axios.get(`${serverurl}/api/gallery/${projectId}/${timelineLength - 1}/ready`)
                  .then((response) => {
                    const data = response.data;
                    if (data.isReady) {
                      // Stop polling and perform necessary actions
                      clearInterval(pollIsReady);
                      getProject(projectId);
                      setIsProessingRender(false);
                      setIsProcessingMore(false);
                    }
                  })
                  .catch((error) => {
                    console.error('Error polling isReady:', error);
                  });
              }, pollingInterval);
        
            // Cleanup function to stop polling when component unmounts or relevantGallery changes
            return () => {
              clearInterval(pollIsReady);
            };
    }
    }, [relevantGallery.isReady, timelineLength]);

    useEffect(() => {
        if(projectId){
            getProject(projectId);
        }   
    }, [projectId]);

    useEffect(() => {
        setProjectId(match.params.id);
        
    }, []);
    
    useEffect(() => { // Make sure the mobile opens the page at the top of it
        window.scroll(0, 0);
      }, []);
    
    const getProject = (projectId) => {
        axios.get(`${serverurl}/api/project/`+projectId)
        .then((response) => {
            setrelevantGallery(response.data);
            setTimelineLength(Object.keys(response.data.timeline).length);
            // if(isProcessingRender)
            // setIsProessingRender(false);
        })
        .catch((error) => {
            console.error('Error fetching gallery:', error);
        });
    }

    
    const openModal = (url, index) => {
        setSelectedImgUrl(url);
        let imageObject = {
            'url': url,
            'index': index
        };
        setSelectedImg(imageObject);
        setModalIsOpen(true);
    };

    const handleClose = () => {
        setSelectedImg('');
        setModalIsOpen(false);
    };
    const handleBack = () => {
        const lastPreviousRoute = localStorage.getItem('lastPreviousRoute');

        if (lastPreviousRoute === '/' || lastPreviousRoute === '/renders') {
          navigate(lastPreviousRoute);
        } else {
          navigate('/');
        }
      };
      
      /// GALLERY MODAL //////
    const handlePrevImg = () => {
        let imgArrayLength = relevantGallery.images.length;
        let currentIndex = selectedImg.index;
        let nextIndex;
        nextIndex = currentIndex < imgArrayLength -1 ? nextIndex= currentIndex+1: nextIndex =0;
        let nextImageObject = 
            {
                'url': relevantGallery.images[nextIndex],
                'index': nextIndex
            }
        setSelectedImg(nextImageObject);
    };

    const handleNextImg = () => {
        let imgArrayLength = relevantGallery.images.length;
        let currentIndex = selectedImg.index;
        let nextIndex;
        nextIndex = currentIndex > 0 ? nextIndex= currentIndex-1: nextIndex = imgArrayLength-1;
        let nextImageObject = 
            {
                'url': relevantGallery.images[nextIndex],
                'index': nextIndex
            }
        setSelectedImg(nextImageObject);
    };
    
    const handleKeyDown = useCallback((event) => {

        
        if (event.key === 'ArrowRight') {
            handleNextImg();

        } else if (event.key === 'ArrowLeft') {
            handlePrevImg();
        }
    }, [selectedImg]);

    const handleShareWhatsapp = () => {
        window.open(`https://wa.me/?text=Check%20out%20this%20render%20made%20in%20TypeX!%0A%0A${selectedImgUrl}`);
    };
    const handleShareFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${selectedImgUrl}&quote=Check%20out%20this%20render%20made%20in%20TypeX!%0A%23typex%20%23generativeai%0A`);
      };
    const handleShareTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${selectedImgUrl}&text=Check%20out%20this%20render%20made%20in%20TypeX!%0A%23typex%20%23generativeai%0A`);
    };

    
    const handleCopyClick = () => {
    navigator.clipboard.writeText(selectedImg.url);
    handleSnackbarOpen();
    };

    const handleSendLikeThisButtonClick = () => {
        setIsProcessingMore(true);
        setModalIsOpen(false);
        const file = {
            ...selectedImg,
            thumbnailUrl:relevantGallery.originalthumburl,
            viewUrl: relevantGallery.originalviewurl
        };
        sendFile(file, false);
    };
    /// END OF GALLERY MODAL //////

    const handleShareProjectMobile = async () => {
        try {
          await navigator.share({
            url: window.location.href,
            text: "Check out this render made in TypeX!",
          });
        } catch (err) {
          console.error("Error sharing:", err);
        }
      };

    

    const handleDownload = async (url,index) => {
        try {
            if (navigator.share) {
                await navigator.share({
                  url: url,
                  title: `typex-image-${index}.jpg`,
                });
              } else {
            const response = await axios.get(url, { responseType: 'blob' });
            const url1 = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url1;
            link.setAttribute('download', `typex-image-${index}.jpg`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
              }
        } catch (error) {
            console.error('Error downloading image:', error);
        }
        };
    
      const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
      };
      
      const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };

      const toggleShowFullText = () => {
        setShowFullText(!showFullText);
      };
      
      const handleShareProject = () => {
        navigator.clipboard.writeText(window.location.href);
        handleSnackbarOpen();
      }



    
    /// SENDING FOR GENERATE MORE IMAGES /////

    const handleSendButtonClick = () => {
        mixpanel.track('Clicked Generate More Renders');
        setIsProcessingMore(true);
        
        if (relevantGallery.fileType === 1 || relevantGallery.fileType === undefined) { // the second condition is to support old projects without file type
            sendFile({
                type:1,
                url:relevantGallery.originalimageurl,
                thumbnailUrl: relevantGallery.originalthumburl,
                viewUrl:relevantGallery.originalviewurl,
            }, false);
        } else if (relevantGallery.fileType !== 5) {
            handleSaveView(); // Tell FbxPlayer to save the current canvas and send it as formdata to handleFileUploadPerspective
        }
        
        };

    const handleFileUploadPerspective = (formData) => {   
        //   formData.append('originalFile', file.url);
          fetch(serverurl+'/api/upload', {
            method: 'POST',
            body: formData
          })
          .then(response => response.text())
          .then(data => {
              handleUploadPerspectiveSuccess(JSON.parse(data));
          })
          .catch(error => console.error(error));
      };

    const handleUploadPerspectiveSuccess = (fileObject) =>{
        sendFile(fileObject, true);
    };
    const handleSaveView = () => {
        setTriggerUseView(true);
    };

    const handleUseViewFunctionComplete = () => {
        setTriggerUseView(false);
    };

    const sendFile = (file, is3D) => {
    
        const token = localStorage.getItem("accessToken");
        const timelineIndex = relevantGallery.timeline.length-1;
        
        let newPromptText;
        let newNegativePromptText = relevantGallery.timeline[timelineIndex].negativePromptText;


        if(relevantGallery.timeline[timelineIndex].prompttext !== editedPrompt && editedPrompt!==''){
            newPromptText = editedPrompt;
        }
        else newPromptText = relevantGallery.timeline[timelineIndex].prompttext;
        
        fetch(serverurl+'/api/send?projectId='+id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ 
            "url" : file.url,
            "thumbnailUrl": file.thumbnailUrl,
            "viewUrl":file.viewUrl,
            "promptText": newPromptText,       
            "negative": newNegativePromptText
           })
        })
        .then(response => response.text())
        .then(data => sendToAstriaSucces(JSON.parse(data)))
        .catch(error => sendToAstriaError(error));
      }

    const sendToAstriaSucces = (data) => {
        if(data){

            // handleSuccessSnackbarOpen();
            const updatedUser = {
                ...user,
                credits: user.credits - 1,
              };
            //updateUser(updatedUser);
            // navigate("/gallery/"+data.id);
            
            setrelevantGallery(data);
            setTimelineLength(Object.keys(data.timeline).length);
        }
        else {
            // handleErrorInSend();
        }
    }
    const sendToAstriaError = (error) => {
        console.log(error);
        setIsProcessingMore(false);
        handleErrorInSend();

    }
    const handleErrorInSend = () => {
        setFailSnackbarOpen(true);
    };
    const handleFailSnackbarClose = () => {
        setFailSnackbarOpen(false);
      };


    const FailSnackBar = () => {

        return (
            <Snackbar
                    open={failSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleFailSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                    <SnackbarContent
                        message={
                          <span>Failed to send file, please make sure you write a meaningful prompt and try again.
                        <br/>If this issue persist, please contact ai@typex.ai</span>}
                        style={{ backgroundColor: 'red' }}
                    />
            </Snackbar>
        );
    }
    /// END SENDING FOR GENERATE MORE IMAGES /////

    /// EDIT PROPMT MODAL //////
  
    const handleEditModalClose = () => {
        setEditModalIsOpen(false);
    };
        

    const handleEditPromptTextFieldChange = (event) => {
        setEditedPrompt(event.target.value);
      };

    const handleSendAfterEditButtonClick = () => {
        
        handleSendButtonClick();
        setEditModalIsOpen(false);
    }

    const handleEditPromptClick = () => {
        setEditModalIsOpen(true);
        setEditedPrompt(relevantGallery.timeline[relevantGallery.timeline.length-1].prompttext);
    }

    const handleStartFreeClick = () => {
        mixpanel.track('Clicked Start Free Now', {'source':'project page'});
        navigate('/new');
    };
    const handleCreateNewClick = () => {
        mixpanel.track('Clicked Create New', {'source':'project page'});
        navigate('/new');
    };

    //// ORIGINAL IMAGE METADATA //////
    const handleOringinalImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        // console.log('Image dimensions:', naturalWidth, naturalHeight);
      };
    ////  END OF ORIGINAL IMAGE METADATA //////
    //// MOBILE GALLERY /////
    const containerRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(260);
    const imageRefs = useRef([]);
      

    const handleTouchStart = (e) => {
        setTouchStartPosition(e.touches[0].clientY);
      };
      
      const handleTouchMove = (e) => {
        setTouchCurrentPosition(e.touches[0].clientY);
      };
      
      const handleTouchEnd = () => {
        if (touchStartPosition && touchCurrentPosition) {
          const touchDistance = touchCurrentPosition - touchStartPosition;

          if (touchDistance > 40) {
            // Handle closing full screen (dragged down)
            handleExitFullScreen();
          } else if (touchDistance < -40) {
            // Handle additional actions (dragged up)
            handleExitFullScreen();
          }
        }
      
        setTouchStartPosition(null);
        setTouchCurrentPosition(null);
      };
      

    const handleExitFullScreen = () => {
        setIsMobileFullScreen(false);
    }
    const handleImageLoad = useCallback((event) => {
        setMaxHeight(event.target.offsetHeight);
      });


      const MobileGalleryItem = React.forwardRef(({ index, length ,image , onImageLoad}, ref) => {
        const left = `${index * window.innerWidth}px`;
        
        const handleOpenMobileFullScreen = () => {
            setIsMobileFullScreen(true);
        };
    
        return (
            <Box ref={ref} key={index} sx={{ position: 'absolute',
                                            left,
                                            scrollSnapAlign: "start",width:'100%',
                                            
                                            }} 
                                            onClick={() => handleOpenMobileFullScreen()}
                                             >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img className='gallery-image' src={image} alt={`Item ${index}`} onLoad={onImageLoad} style={{ 
                    filter:isProcessingRender?'blur(10px)':'blur(0px)', transition:'filter 3s ease-in-out',
                    objectFit: 'cover', width: '100%', height: '100%' }}
                    />
                <Box sx={{position:'absolute',borderRadius:2,bottom:'15px', left:'15px', backgroundColor:'#000000aa', color:'white', fontSize:'.8em', px:1, py:.5}}><b>{index+1} / {length}</b></Box>
                {isProcessingRender && <CircularProgress sx={{position:'absolute', color:'white'}} />}
                </Box>
            </Box>
        );
    });

    ///// END OF MOBILE GALLERY //// 

    return (
        <>
        <Helmet>
        <meta
            property="og:image"
            key="og:image"
            content={`https://typexcad-cc750.web.app/typex.png`}
        />  
        </Helmet>
        <Box id='mobile-gallery-wrapper' sx={{position:isMobileFullScreen?'absolute':'',
                                                    width: '100%',
                                                    left: 0,
                                                    zIndex:'10000'
                                                }}
                                        
                                            >
        <Box id="gallery-grid-mobile" sx={{display:{xs:'flex', sm:'none'},
                                            position:'relative' , 
                                            overflow:'auto',
                                            alignItems:'center',
                                            m:0,
                                            scrollSnapType: "x mandatory",
                                            "&::-webkit-scrollbar": {display: "none"},
                                            overflowY: 'hidden',
                                            height: isMobileFullScreen ? '100vh': maxHeight,
                                            backgroundColor:isMobileFullScreen? '#000000ff':'#00000000',
                                            transition:'.3s all'
                                            }}
                                            ref={containerRef}
                                            // onTouchStart={handleTouchStart}
                                            // onTouchMove={handleTouchMove}
                                            // onTouchEnd={handleTouchEnd}
                                            >
            {relevantGallery.images && relevantGallery.images.slice().reverse().map((image, index) => (
                <MobileGalleryItem ref={el => imageRefs.current[index] = el}  key={`mobile-gallery-item-${index}`} length={relevantGallery.images.length} index={index} image={image} onImageLoad={handleImageLoad}/>
                ))}
             
        </Box>
        {isMobileFullScreen && <>
         <IconButton
                    aria-label="Close gallery"
                    onClick={handleExitFullScreen}
                    sx={{ position: 'absolute', top:16, right:16 }}
                    style={{ color: 'white' }}
                    >
                        <CloseIcon fontSize="large"/>
                    </IconButton>
            <Box id='mobile-bottom-action-bar' sx={{ width:'100%',position:'absolute', bottom:0, width:'100%', background:'#6434E4', display:{xs:'flex', sm:'block'}}} >
                        <Box id="mobile-inner-action-bar" sx={{display:'flex', width:'100%', justifyContent:'right',p:1}}>
                            {/* <IconButton
                                aria-label="download this image"
                                onClick={() => handleDownload(selectedImg.url+'?_', selectedImg.index)}>
                                <SaveIcon sx={{color:'white'}}/>
                            </IconButton>  */}
                        
                            {/* <IconButton onClick={handleShareWhatsapp}>
                                <WhatsAppIcon sx={{color:'white'}} />
                            </IconButton>
                            <IconButton onClick={handleShareFacebook}>
                                <FacebookIcon sx={{color:'white'}} />
                            </IconButton>
                            <IconButton onClick={handleShareTwitter}>
                                <TwitterIcon sx={{color:'white'}} />
                            </IconButton> */}
                            <IconButton
                                aria-label="share project"
                                onClick={handleShareProjectMobile}>
                                <IosShareIcon sx={{color:'white'}} fontSize='large'/>
                            </IconButton>

                        </Box>
                    </Box>
                    {user && relevantGallery.creatorUserId === user.uid && <Box id='mobile-more-like-this-wrapper' sx={{width:'100%',position:'absolute',bottom:'90px', display:'flex', justifyContent:'center'}}>
                     <LoadingButton loadingPosition="start"
                                startIcon={<AutoAwesomeIcon />} 
                                loading={isProcessingMore}
                                variant="contained" 
                                onClick={()=>{
                                    setIsMobileFullScreen(false);
                                    handleSendLikeThisButtonClick();
                                }}
                                disabled={isProcessingRender}
                                sx={{height:'56px',borderRadius:'28px', mt:4, boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',}}>
                            <span>Render More Like This</span>
                    </LoadingButton>
                    </Box>}
                    </>}
        
        </Box>
        
        {/* {relevantGallery.images && <Box id="gallery-image-index" sx={{position:'absolute',borderRadius:2,bottom:'15px', right:'15px', backgroundColor:'#000000aa', color:'white', fontSize:'.8em', px:1, py:.5}}><b>{visibleImageIndex} / {relevantGallery.images.length}</b></Box>} */}
        <Box sx={{height:'100vh'}}>
        {/* {isLoading && <CircularProgress sx={{position:'absolute', zIndex:'100', top:'50%', left:'50%'}} />} */}
            <Grid id="project-grid" sx={{height:'100%'}} container>
                <Grid id="project-details-grid" item xs={12} sm={4} sx={{p:2, pt:{sm:8, xs:4}, height:{xs:'100%',sm:'100%'},  background:'#eaeaea'}}>

                <Box sx={{display:'flex', flexDirection:'row'}}>
                    <Box sx={{flex:1,  pb:2}}>
                        <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>Back</Button>
                    </Box>
                    {isShareSupported?
                    (
                    <Box sx={{display:{xs:'flex', sm:'none'}, justifyContent:'flex-end',  pb:2}}>
                        <Button endIcon={<IosShareIcon fontSize='large'/>} onClick={handleShareProjectMobile}>Share</Button>
                    </Box>
                    ):(
                    <Box sx={{display:{xs:'flex', sm:'none'}, justifyContent:'flex-end',  pb:2}}>
                        <Button endIcon={<InsertLinkIcon />} onClick={handleShareProject}>Get sharing link</Button>
                    </Box>
                    )
                    }
                </Box>
                {relevantGallery.creator &&
                        <Box sx={{display: 'flex', flexDirection:'column', pl:2, pr:2, justifyContent:'flex-start'}}>
                           <Box id="creator-title">
                                <Typography>Made by <b>{relevantGallery.creator.displayName}</b></Typography>
                            </Box>
                            {/* <Box sx={{flex:1}}> */}
                           
                          
                            {/* <Box id="date-wrapper">
                                <Typography variant='caption'>Created <b>{relevantGallery.entryDate}</b></Typography>
                            </Box> */}
                            <Typography variant='caption' sx={{display:'flex', alignItems:'center',textTransform:'uppercase', mt:2}}>{relevantGallery.is3D? 'Original Model':'Original Image'}</Typography>
                            
                            {relevantGallery && relevantGallery.is3D ? (
                               <FbxPlayer 
                               onUploadPerspective={handleFileUploadPerspective} 
                                triggerUseView={triggerUseView} 
                                onCompleteUseView={handleUseViewFunctionComplete}
                               objUrl={relevantGallery.original3DFileUrl}
                               type={relevantGallery.fileType}
                               
                               />
                            ):(
                                <Box sx={{mt:2}}>
                                <Card>
                                    <CardMedia onLoad={handleOringinalImageLoad} component="img" image={relevantGallery.originalviewurl || relevantGallery.originalimageurl} />
                                </Card>
                            </Box>
                             
                                )}
                                <Typography variant='caption' sx={{display:{sm:'none', xs:'flex'}, alignItems:'center',textTransform:'uppercase', mt:4}}>Description</Typography>
                            <Box sx={{flex:2, mt:2, display:{sm:'none', xs:'block'}}}>
                            {relevantGallery.prompttext.length <= 200 ? (<>
                                <Typography variant="subtitle2">{relevantGallery.prompttext}</Typography>
                                {relevantGallery.negativePromptText && <Box sx={{mt:1}}>
                                <Typography variant="subtitle2" fontWeight={100} color='#888'>Exclude (Negative prompt):</Typography>
                                <Typography variant="subtitle2">{relevantGallery.negativePromptText}</Typography>
                                </Box>
                                }
                                </>
                            ) : (
                                <>
                                <Typography variant="subtitle2" sx={{textOverflow:'ellipsis', overflow:'hidden'}}>
                                    {showFullText ? relevantGallery.prompttext : `${relevantGallery.prompttext.slice(0, 200)}...`}
                                </Typography>
                                {showFullText && relevantGallery.negativePromptText && <Box sx={{mt:1}}>
                                <Typography variant="subtitle2" fontWeight={100} color='#888'>Exclude (Negative prompt):</Typography>
                                <Typography variant="subtitle2">{relevantGallery.negativePromptText}</Typography>
                                </Box>}
                                {!showFullText && (
                                    <Typography variant="subtitle2" onClick={toggleShowFullText} sx={{cursor: 'pointer'}}><Link underline='none'>Show more</Link></Typography>
                                )}
                                {showFullText && (
                                    <Typography variant="subtitle2" onClick={toggleShowFullText} sx={{cursor: 'pointer'}}><Link underline='none'>Show less</Link></Typography>
                                )}
                                </>
                            )}
                            </Box>
                        </Box>}
                    
                </Grid>
                <Grid id="gallery-grid" item xs={12} sm={8} sx={{display:{xs:'none', sm:'block'} ,p:2, pt:{xs: 3,sm:8}, height:{xs:'60%',sm:'100%'}, overflow:'auto'}}>
                    <Box sx={{display:{xs:'none',sm:'flex'}, justifyContent:'flex-end',  pb:2}}>
                        <Button endIcon={<InsertLinkIcon />} onClick={handleShareProject}>Get sharing link</Button>
                    </Box>
                    {relevantGallery && relevantGallery.timeline && relevantGallery.timeline.length>0  &&
                    <Box id="timeline-step">
                        {relevantGallery.timeline.slice().reverse().map((step, index, array)=>{
                        const isFirstItem = index === 0;
                        const previousPromptText = isFirstItem ? null : array[index - 1].prompttext;
                             
                        return (
                        <Box key={`step-${index}`} sx={{mb:2}}>
                            {step.prompttext !== previousPromptText && (
                            <Box id='step-prompt-wrapper' sx={{px:6, mb:2}}>
                                    {relevantGallery.prompttext.length <= 300 ? (
                                    <>
                                        <Typography variant="subtitle2">{step.prompttext}</Typography>
                                        {step.negativePromptText && <Box sx={{mt:1}}>
                                         <Typography variant="subtitle2" fontWeight={100} color='#888'>Exclude (Negative prompt):</Typography>
                                        <Typography variant="subtitle2">{step.negativePromptText}</Typography>
                                        </Box>
                                        }
                                    </>
                                    ) : (
                                        <>
                                        <Typography variant="subtitle2" sx={{textOverflow:'ellipsis', overflow:'hidden'}}>
                                            {showFullText ? step.prompttext : `${step.prompttext.slice(0, 200)}...`}
                                        </Typography>
                                        {showFullText && step.negativePromptText && <Box sx={{mt:1}}>
                                         <Typography variant="subtitle2" fontWeight={100} color='#888'>Exclude (Negative prompt):</Typography>
                                        <Typography variant="subtitle2">{step.negativePromptText}</Typography>
                                        </Box>
                                        }
                                        {!showFullText && (
                                            <Typography variant="subtitle2" onClick={toggleShowFullText} sx={{cursor: 'pointer'}}><Link underline='none'>Show more</Link></Typography>
                                        )}
                                        {showFullText && (
                                            <Typography variant="subtitle2" onClick={toggleShowFullText} sx={{cursor: 'pointer'}}><Link underline='none'>Show less</Link></Typography>
                                        )}
                                        </>
                                    )}
                                    {/* <Typography variant="subtitle2">{step.prompttext}</Typography> */}
                                </Box>
                            // </Box>
                            )}
                            {step.images && step.images.length>0 && 
                            <Grid container spacing={2} sx={{px:{xs:3, sm:6}}}>
                                {step.images.map((image, indexImg) => (
                                    <Grid item xs={12} sm={6} key={indexImg}>
                                        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <CardMedia sx={{cursor:'pointer' ,filter:!step.isReady && (isProcessingRender || isProcessingMore)?'blur(10px)':'blur(0px)', transition:'filter 3s ease-in-out'}} component="img" image={image} onClick={() => openModal(image, index)} />
                                        {!step.isReady && (isProcessingRender || isProcessingMore) && <CircularProgress sx={{position:'absolute', color:'white'}} />}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>}
                        </Box>
                    )})}
                    </Box>}
                    
                   <Box
                        id="generate-more-wrapper"
                        sx={{
                        position: 'sticky',
                        bottom: 0,
                        display: {sm:'flex' , xs:'none'},
                        justifyContent: 'center',
                        mt: 2,
                        bgcolor: '#fafafa',
                        p: 3,
                        mx:-2,
                        transform:'translateY(18px)',
                        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)'
                        }}
                        >
                    {user && relevantGallery.creatorUserId === user.uid && <>
                        <Button disabled={isProcessingRender || isProcessingMore} variant='outlined' onClick={handleEditPromptClick}>Edit Prompt</Button>
                         <LoadingButton loadingPosition="start"
                                        startIcon={<AutoAwesomeIcon />} 
                                        loading={isProcessingMore}
                                        variant="contained" 
                                        onClick={handleSendButtonClick}
                                        disabled={isProcessingRender}
                                        sx={{ml:2,boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.5)',}}>
                            <span><>{isProcessingMore? 'Generating':'Generate'}</> more renders</span>
                        </LoadingButton>
                      
                        </>}   
                    {!token && 
                    <Button variant='contained' onClick={handleStartFreeClick}>
                        <AutoAwesomeIcon />&nbsp; Start Now Free!
                    </Button>
                    }
                    {user && relevantGallery.creatorUserId !== user.uid && 
                    <Button variant='contained' onClick={handleCreateNewClick}>
                        <AutoAwesomeIcon />&nbsp;Create New Render
                    </Button>
                    }
                    </Box>
                    
                    
                </Grid>
            </Grid>
            {/* MOBILE */}
            
            <Box sx={{display:{sm:'none', xs:'flex', width:'100%',justifyContent:'center', position:'fixed', bottom:50}}}>
            {user && relevantGallery.creatorUserId === user.uid && <>
                 <Button sx={{boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
                  mr:2,backgroundColor:'white', borderRadius:'28px',
                  '&:active': {
                    backgroundColor: 'white', // Set the background color on active state
                  },
                  '&:hover': {
                    backgroundColor: 'white', // Set the background color on hover
                  },}} 
                  disabled={isProcessingRender || isProcessingMore}
                  variant='outlined'
                  onClick={handleEditPromptClick}
                  
                  >
                    Edit Prompt
                </Button>
             <LoadingButton loadingPosition="start"
             startIcon={<AutoAwesomeIcon />} 
             loading={isProcessingMore}
             variant="contained" 
             onClick={handleSendButtonClick}
             sx={{boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)', height: '56px',borderRadius:'28px',
             '&.Mui-disabled': {
                color: '#999', // Specify the text color for disabled state
                backgroundColor: '#ccc', // Specify the background color for disabled state
                boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)'
              } 
             }}>
                 <span><>{isProcessingMore? 'Generating':'Generate'}</> more</span>
             </LoadingButton>
             </>}
             {!token && 
                    <Button variant='contained' onClick={handleStartFreeClick} sx={{height: '56px',borderRadius:'28px'}}>
                        <AutoAwesomeIcon />&nbsp; Start Now Free!
                    </Button>
                    }
                    {user && relevantGallery.creatorUserId !== user.uid && 
                    <Button variant='contained' onClick={handleCreateNewClick} sx={{height: '56px',borderRadius:'28px'}}>
                        <AutoAwesomeIcon />&nbsp;Create New Render
                    </Button>
                    }
             </Box>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
            <SnackbarContent
                message="Link copied!"
                style={{ backgroundColor: 'green' }}
            />
        </Snackbar>
        <Modal  open={modalIsOpen} onClose={handleClose}>
                <Box tabIndex="0" onKeyDown={handleKeyDown} sx={style} >
                    <IconButton
                    aria-label="previous image"
                    onClick={handlePrevImg}
                    sx={{ position: 'absolute', left: '-50px', top: '50%', transform: 'translateY(-50%)' }}
                    style={{ color: 'white' }}
                    >
                        <ArrowCircleLeft fontSize="large"/>
                    </IconButton>
                    <Box style={{position:'relative'}}>
                        <img
                        src={selectedImg.url}
                        style={{
                            maxWidth: "80vw", /* set maximum width to 80% of screen width */
                            maxHeight: "80vh",
                            margin: 0,
                            padding:0,
                            display: "block"
                        }}
                        />
                    </Box>
                    {user && relevantGallery.creatorUserId === user.uid && <Box id='more-like-this-wrapper' sx={{position:'absolute',bottom:'60px', right:'20px'}}>
                    <LoadingButton loadingPosition="start"
                                startIcon={<AutoAwesomeIcon />} 
                                loading={isProcessingMore}
                                variant="contained" 
                                onClick={handleSendLikeThisButtonClick}
                                disabled={isProcessingRender}
                                sx={{mt:4, boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',}}>
                            <span>Render More Like This</span>
                </LoadingButton>
                    </Box>}
                  
                    <Box sx={{background:'#6434E4', justifyContent:'center', display:{xs:'flex', sm:'block'}}} >
                        <Box >
                            <IconButton
                                aria-label="download this image"
                                onClick={() => handleDownload(selectedImg.url+'?_', selectedImg.index)}>
                                <SaveIcon sx={{color:'white'}}/>
                            </IconButton> 
                        
                            <IconButton onClick={handleShareWhatsapp}>
                                <WhatsAppIcon sx={{color:'white'}} />
                            </IconButton>
                            <IconButton onClick={handleShareFacebook}>
                                <FacebookIcon sx={{color:'white'}} />
                            </IconButton>
                            <IconButton onClick={handleShareTwitter}>
                                <TwitterIcon sx={{color:'white'}} />
                            </IconButton>
                            <IconButton
                                aria-label="copy image URL to clipboard"
                                onClick={handleCopyClick}>
                                <ContentCopyIcon sx={{color:'white'}}/>
                            </IconButton>

                        </Box>
                    </Box>
                <IconButton
                    aria-label="previous image"
                    onClick={handleNextImg}
                    sx={{ position: 'absolute', right: '-50px', top: '50%', transform: 'translateY(-50%)' }}
                    style={{ color: 'white' }}
                    >
                        <ArrowCircleRight fontSize="large"/>
                    </IconButton>
                </Box>
        </Modal>
        
        {relevantGallery && relevantGallery.timeline && <Modal open={editModalIsOpen} onClose={handleEditModalClose}>
           <Box sx={style}>
                <Box sx={{ minWidth:'80vw',borderRadius:2, background: 'white', p: 4 , display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <TextField
                    id="outlined-multiline-static"
                    label="Prompt"
                    multiline
                    onChange={handleEditPromptTextFieldChange}
                    defaultValue={relevantGallery.timeline[relevantGallery.timeline.length-1].prompttext}
                    fullWidth
                />
                <LoadingButton loadingPosition="start"
                                startIcon={<AutoAwesomeIcon />} 
                                loading={isProcessingMore}
                                variant="contained" 
                                onClick={handleSendAfterEditButtonClick}
                                disabled={isProcessingRender}
                                sx={{mt:4, boxShadow: '0px 0px 20px rgba(255, 255, 255, 0.5)',}}>
                            <span>Looks Good, Let's Generate More</span>
                </LoadingButton>
                </Box>
            </Box>
        </Modal>}
        </Box>
        <FailSnackBar />
        </>
    )
}

export default Project;